@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,600,700|Tangerine:300,400,600,700|Cookie:100,300,400,600,700|Exo:100,300,400,600,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@-webkit-keyframes smallBeat {
    from { -webkit-transform: scale(1); transform: scale(1); }
    50%   { -webkit-transform: scale(1.025); transform: scale(1.025); }
    to   { -webkit-transform: scale(1); transform: scale(1); }
}
@keyframes smallBeat {
    from { -webkit-transform: scale(1); transform: scale(1); }
    50%   { -webkit-transform: scale(1.025); transform: scale(1.025); }
    to   { -webkit-transform: scale(1); transform: scale(1); }
}

#NewerFrontPage h2 {text-align: center; margin: 60px 0 40px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
  #NewerFrontPage h2 hr {-webkit-flex: 1 1;flex: 1 1;}
  #NewerFrontPage h2 span {padding: 0 15px;}
#NewerFrontPage .center {text-align: center;}
#NewerFrontPage .btn {font-weight: 300;}
#NewerFrontPage .new {
  background-color: var(--bear);
  position: absolute;
  left: 0;
  top: 0;
  color: var(--c1);
  text-transform: uppercase;
  width: 75px;
  height: 75px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: translate(-75%,-50%);
          transform: translate(-75%,-50%);
  border-radius: 50%;
  font-family: var(--font2);
  font-size: 18px;
}

#NewerFrontPage .top {position: relative; padding-top: 15vh;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#255389+0,388bec+50,ffffff+100 */
  background: rgb(37,83,137); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
  #NewerFrontPage .top .bg { position: absolute; width: 100%; height: 100vh; opacity: 0.1; top: 0;}
  #NewerFrontPage .top h1 {color: var(--c1); font-size: 53px;}
  #NewerFrontPage .top .h1bold {color: var(--c1); font-size: 53px; font-weight: 400;}
  #NewerFrontPage .top h4 {color: var(--c8); font-weight: 400; font-size: 20px; line-height: 26px;}
  #NewerFrontPage .top .soon {color: var(--alert); font-size: 53px;}
  #NewerFrontPage .top .ars {color: #74ACDF; }
  #NewerFrontPage .top .btc {color: #F2A900; }
  #NewerFrontPage .top .programs {
    position: relative;
    background-color: var(--c1);
    border-radius: 5px;
    border: 2px solid var(--bear);
    margin: 40px 0 0;
  }
  #NewerFrontPage .top .programs h3 {
    padding: 15px 25px;
    margin: 0;
    font-weight: 100; 
    color: var(--c1);
    background: rgb(85,85,85);
    background: linear-gradient(to right, rgba(85,85,85,1) 0%,rgba(17,17,17,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#555555', endColorstr='#111111',GradientType=1 );
  }
  #NewerFrontPage .top .programs img {position: absolute; top: 5%; right: 5%; height: 90%;}
  #NewerFrontPage .top .programs .text {padding: 15px 25px;}

#NewerFrontPage .Coinvenience {background-color: var(--c1); border-radius: 5px; box-shadow: var(--small-shadow); padding: 20px;}

#NewerFrontPage .trendContainer {border-radius: 5px; box-shadow: var(--small-shadow); padding: 20px; background-color: var(--c1);}

#NewerFrontPage .IcoTimer {position: absolute; top: 5px; right: 10px; border-radius: 5px; background-color: rgba(0,0,0,0.25); color: var(--c1); padding: 2px 10px; font-size: 12px; font-weight: 100;}

#NewerFrontPage .imgTextCombo {margin-bottom: 50px; display: -webkit-flex; display: flex;}
#NewerFrontPage a {text-decoration: none;}
#NewerFrontPage a .imgTextCombo {color: var(--c8); transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s;}
#NewerFrontPage a .imgTextCombo:hover {background-color: var(--c2); -webkit-transform: scale(1.05); transform: scale(1.05);}
#NewerFrontPage .imgTextCombo.vertical {-webkit-flex-direction: column;flex-direction: column;}
/*#NewerFrontPage .imgTextCombo .image {animation-name: smallBeat; animation-duration: 0.25s; animation-iteration-count: infinite; transform-origin: 0 50%;}*/
#NewerFrontPage .imgTextCombo .image {position: relative; transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s; -webkit-transform-origin: 0 50%; transform-origin: 0 50%;}
/* #NewerFrontPage .imgTextCombo .image:hover {transform: scale(1.1);} */
#NewerFrontPage .imgTextCombo .text {padding: 0 15px;}
#NewerFrontPage .imgTextCombo.vertical .text {padding: 20px 20px 0;}
#NewerFrontPage .imgTextCombo h3 {margin-bottom: 10px;}

#NewerFrontPage .eightR {background-color: var(--c2); padding: 25px 15%; border-radius: 5px; -webkit-align-items: center; align-items: center;}
  #NewerFrontPage .eightR h5 {line-height: 22px;}
  #NewerFrontPage .eightR img {width: 100%;}

#NewerFrontPage .whiteTopBox {background-color: var(--c1); padding: 25px 15%; border-radius: 5px; -webkit-align-items: center; align-items: center;}
#NewerFrontPage .whiteTopBox h5 {line-height: 22px;}
#NewerFrontPage .whiteTopBox img {width: 100%;}

#NewerFrontPage .shadowBox {box-shadow: var(--small-shadow); padding: 20px 0;}
#NewerFrontPage .shadowBox .image {padding-left: 20px;}
#NewerFrontPage .shadowBox .image:hover {-webkit-transform: none;transform: none;}
#NewerFrontPage .shadowBox.vertical {padding-top: 0;}
#NewerFrontPage .shadowBox.vertical img {width: 100%;}

#NewerFrontPage .whiteBox {box-shadow: var(--small-shadow); padding: 20px 0; background-color: var(--c1);}
#NewerFrontPage .whiteBox .image {padding-left: 0px;}
#NewerFrontPage .whiteBox .image:hover {-webkit-transform: none;transform: none;}
#NewerFrontPage .whiteBox.vertical {padding-top: 0;}
#NewerFrontPage .whiteBox.vertical img {width: 100%;}

#NewerFrontPage .shadowTopBox img {width: 100%;}
#NewerFrontPage .shadowTopBox .smallimage {width: 30%;}


#NewerFrontPage .banner img {cursor: pointer;}
#shortLanding {min-height: calc(100vh - 308px); background-color: var(--c8);}
  #shortLanding .top {
    background: #555555;
    background: linear-gradient(to bottom, #555555 0%, rgba(17,17,17,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#555555', endColorstr='#111111',GradientType=0 );
  }
    #shortLanding .top > div {position: relative; height: 300px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; padding: 0 30px;
      background: url(/img/logo-g.png); background-repeat: no-repeat; background-position: 90% center; background-size: auto 90%;
    }
    #shortLanding .top h1 {color: var(--c1); font-weight: 300; font-size: 60px; margin-bottom: 10px;}
    #shortLanding .top h3 {color: var(--c4); font-weight: 300; font-size: 24px; margin-bottom: 30px;}
    #shortLanding .top .btn {display: block;}
  #shortLanding .main {padding-top: 15px;}
    #shortLanding .main .container {display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 1rem;}
    #shortLanding .main .container::after, #shortLanding .main .container::before{ display:none !important;}
    #shortLanding .main .item {background-color: var(--c7); color: var(--c1); margin-bottom: 15px;}
    #shortLanding .main .img {height: 180px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; background-size: cover; background-position: center center; position: relative;}
      #shortLanding .main .img a {display: none; text-decoration: none; background-color: var(--c1); padding: 10px; position: absolute; transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s;}
      #shortLanding .main .img:hover a {display: block;}
      #shortLanding .main .img a:hover {-webkit-transform: scale(1.25);transform: scale(1.25);}
      #shortLanding .main .img .ovrly {opacity: 0; transition: opacity 0.5s; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.5);}
      #shortLanding .main .img:hover .ovrly {opacity: 1;}
    #shortLanding .main .text {padding: 10px 20px 25px;}
      #shortLanding .main .text h4 {font-family: var(--font2); font-size: 24px; font-weight: 300; margin-bottom: 10px;}
      #shortLanding .main .text p {font-weight: 300;}

@media (max-width: 990px) {
  #shortLanding .main .container {grid-template-columns: repeat(1, 1fr);}
  #shortLanding .main .img a {display: block; top: 10px; right: 10px;}
  #shortLanding .main .img .ovrly {display: none;}
}
#Referrals {padding-top: 25px;}
  #Referrals h3 {margin: 10px 0;}
  #Referrals h5 {margin-bottom: 20px;}
  #Referrals .form-control {width: 60%; display: inline-block; margin-right: 10px; vertical-align: middle;}
  #Referrals .box a {display: block; text-decoration: none; background-color: var(--brand); color: var(--c8); margin: 8px 0; max-width: 25%; padding: 10px 15px; font-weight: 600; font-size: 15px;}
    #Referrals .box a i {float: right; color: var(--c1); font-size: 22px;}
.my-page .box.referral-link .share a {font-size: 20px; color: var(--brand); margin-right: 20px;}

.appDownloadButtons a {margin-right: 20px; overflow: hidden; display: inline-block;}
  .appDownloadButtons a img {height: 60px;}

#twoFA {}
  #twoFA .step-2 > div {display: inline-block; vertical-align: top; margin-right: 40px; margin-bottom: 20px;}
  #twoFA .step-2 .qr, #twoFA .step-2 .form-control {max-width: 200px; display: inline-block;}
  #twoFA .step-3 .form-control, #twoFA .step-4 .form-control {max-width: 200px;}
  #twoFA .btn-group {white-space: normal;}
  #twoFA .btn-group .btn {margin-bottom: 5px;}

#Kyc p {margin: 20px 0;}
#Kyc .methodSelection .box {cursor: pointer; padding: 20px; margin: 10px 0 20px; border-radius: 8px; box-shadow: 10px 10px 10px 0px rgba(221,221,221,1);}
  #Kyc .methodSelection .box p {margin: 10px 0;}
  #Kyc .methodSelection .box.active {background-color: var(--brand); color: var(--c8);}
  #Kyc .methodSelection .box:hover {background-color: var(--c3); color: var(--c8);}
  #Kyc .methodSelection .box:active, #Kyc .methodSelection .box:focus {background-color: var(--c4);}
#Kyc .appDownload {margin: 15px 0;}
  #Kyc .appDownload > div {display: inline-block; vertical-align: top; margin-right: 30px; margin-bottom: 15px;}
  #Kyc .appDownload h5 {font-size: 15px; font-weight: 400; margin-bottom: 15px;}
  #Kyc .appDownload .downloadLinks {background: var(--brand); color: var(--c1); text-align: center; overflow: hidden; padding: 10px; cursor: pointer;}
  #Kyc .appDownload .downloadLinks.active {background: transparent; border: 1px solid var(--c3);}
    #Kyc .appDownload .downloadLinks > img {width: 75px; display: block; margin: auto; vertical-align: top;}
    #Kyc .appDownload .downloadLinks.active > img {display: inline-block;}
    #Kyc .appDownload .downloadLinks span {margin-top: 10px; display: block; font-family: var(--font2);}
    #Kyc .appDownload .downloadLinks > div {display: inline-block; margin-left: 15px;}
      #Kyc .appDownload .downloadLinks > div a {display: block;}
      #Kyc .appDownload .downloadLinks > div a:first-child {margin-bottom: 10px;}
        #Kyc .appDownload .downloadLinks > div a img {width: 175px;}
#Kyc .manual {}
  #Kyc .manual h4 {font-weight: 500; margin-bottom: 18px; font-size: 20px;}
  #Kyc .manual input, #Kyc .manual .select {max-width: 500px;}
  #Kyc .manual img.illust {margin: 15px 22px; max-width: 150px; max-height: 150px;}
  #Kyc .manual .checkbox {display: inline-block; font-size: 19px; margin: 10px 20px 20px;}
    #Kyc .manual .checkbox input {margin: 5px -20px;} 
  #Kyc .manual .dateOfBirth .select {width: 160px; display: inline-block; margin-right: 10px;}
  #Kyc .manual .document {
    display: inline-block;
    margin: 20px;
    position: relative;
  }
    #Kyc .manual .document img { height: 125px; }
    #Kyc .manual .document span {color: var(--brand);}
    #Kyc .manual .fileUpload span {
      background-color: var(--c6);
      color: var(--c1);
      font-family: var(--font2);
      padding: 5px 0;
      width: 200px;
      display: block;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 20px;
      vertical-align: middle;
      display: inline-block;
    }
    #Kyc .manual .fileUpload span:hover { background-color: var(--c5); }
    #Kyc .manual .fileUpload input {display: none;}
    #Kyc .manual button {border-radius: 5px;}
  .manualKycStatus_INCOMPLETE {color: var(--alert);}
  .manualKycStatus_PROCESSING {color: var(--bluefinity);}
  .manualKycStatus_UPDATE_REQUIRED {color: var(--alert);}
  .manualKycStatus_VERIFIED {color: var(--brand);}
  .manualKycStatus_REJECTED {color: var(--danger);}

  #AutomatedPurchaseProgram {}
  #AutomatedPurchaseProgram .box {padding: 15px; box-shadow: var(--shadow);}
  #AutomatedPurchaseProgram h4 {margin-bottom: 20px; color: var(--c6); font-weight: 400;}
#LoginPage {padding-top: 50px;}
  #LoginPage .auth {position: relative; margin: auto; left: 0; right: 0; padding-bottom: 75px;}
    #LoginPage .auth > .head {display: none;}
    #LoginPage .segment {padding: 0; max-height: none; overflow: hidden;}
  #LoginPage .header-bg {background-image: url('/img/icons/signin.png');}
/* Header */
.Exchange-header {height: 50px; font-family: var(--font); min-width: 1050px; background: var(--c1);}
  .Exchange-header > div, .Exchange-header > div .row, .Exchange-header > div .row > div {height: 100%;}
  .Exchange-header .App-logo {float: left; margin-right: 15px;}
  .Exchange-header .btn-group {height: 100%; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; float: right;}
    .Exchange-header .btn-group .btn {margin-right: 5px; padding: 0px 10px;}
    .Exchange-header .btn-group .connection {margin-right: 5px; padding: 0px 10px;}
    .Exchange-header .btn-info {border: none;}
    
/* Active Pair */
  .activePair {z-index: 40; position: relative; background: var(--c1); height: 100%; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; display: inline-block;}
    .activePair > div {padding: 0 10px; display: inline-block;}
      .activePair > div > span {display: block; white-space: nowrap; color: var(--c7);}
      .activePair > div .name {font-size: 12px; color: var(--c6);}
    .activePair .pair-selection-toggle {cursor: pointer; background: var(--c2); padding: 6px 10px;}
    .activePair .pair-selection-toggle:hover {background: var(--c3);}
    .activePair .pair-selection-toggle.open {background: var(--c4);}
      .activePair .pair-selection-toggle .value {color: var(--c8);}
      .activePair .pair-selection-toggle i {margin-left: 12px; vertical-align: top; transition: all 0.25s;}
      .activePair .pair-selection-toggle.open i {margin-top: 5px;}

  .Exchange-header .pair-selection {position: absolute; background: var(--c1); border: 2px solid var(--c4); top: 100%; left: 138px; z-index: 30; -webkit-transform: translateY(-100%); transform: translateY(-100%); transition: opacity 0s linear 0.5s, -webkit-transform 0.5s; transition: transform 0.5s, opacity 0s linear 0.5s; transition: transform 0.5s, opacity 0s linear 0.5s, -webkit-transform 0.5s; overflow: auto; opacity: 0; width: 710px;}
  .Exchange-header .pair-selection.active {-webkit-transform: translateY(0);transform: translateY(0); opacity: 1; transition: opacity 0s linear 0s, -webkit-transform 0.5s; transition: transform 0.5s, opacity 0s linear 0s; transition: transform 0.5s, opacity 0s linear 0s, -webkit-transform 0.5s;}
    .Exchange-header .pair-selection .tableActions {padding: 2px 4px; margin-bottom: 0;}
    .Exchange-header .pair-selection .pairs {overflow-y: auto; max-height: 200px;}
      .Exchange-header .pair-selection .pairs table .high {display: none;}
      .Exchange-header .pair-selection .pairs table .low {display: none;}
      .Exchange-header .pair-selection .pairs table tr {background: var(--c1);}

      .pairSelection.uiModal {width: auto;}
        .pairSelection.uiModal .body {padding: 0;}
          .pairSelection.uiModal .body .tableActions {margin: 2px 4px;}
          .pairSelection.uiModal .body > button {width: 100%; padding: 4px; margin-top: 5px; border: none;}


#exchange.uiContainer {height: calc(100vh - 50px); min-width: 1050px; min-height: 600px;}
#popout-content-container #exchange {height: 100%; width: 100%;}
  
#exchange .alert {margin: 0;}

/* TABLES */
  #exchange table {font-size: 12px;}
      #exchange th {padding: 4px 10px 2px;}
        #exchange th.active .fa {color: var(--c8);}
    #exchange tbody tr {border: none; /* background: transparent; */}
        #exchange td {padding: 1.5px 10px; letter-spacing: 0.75px; color: var(--c8);}
    #exchange table .checkbox {margin: 0 0 0 20px;}

/* DATE PICKER */
.DateRangePicker_picker {z-index: 30;}
.CalendarDay {background: transparent; color: var(--c1); font-weight: 600; border: none!important; padding: 4px 0!important;}
.CalendarDay:hover {background: var(--c5);}
.CalendarDay__blocked_out_of_range {color: var(--c5);}
.CalendarDay__selected {background: var(--brand);}
.CalendarDay__selected:hover {background: var(--brand);}
.CalendarDay__selected_span {background: #388E00;}
.CalendarDay__selected_span:hover {background: #388E00;}
.DayPicker {background: var(--c6); border: 2px solid var(--c5); overflow: hidden;}
  .DayPicker table {border: none;}
  .DayPicker tr {background: transparent!important; border: none;}
.CalendarDay__today {background: var(--c1); color: var(--c8);}
.CalendarMonthGrid {background: var(--c6);}
.CalendarMonth {background: var(--c6);}
.CalendarMonth_caption {color: var(--c4); font-family: var(--font2); font-weight: 400;}
.DayPicker_weekHeader_li {color: var(--c4); font-family: var(--font2); font-weight: 600;}
.DayPickerNavigation_button {background: transparent; border: none;}
  .DayPickerNavigation_button svg {fill: var(--brand);}
/*.DateRangePicker_picker {position: fixed; z-index: 400; top: 50% !important; left: 50% !important; transform: translate(-50%,-50%);}*/
.DateRangePickerInput {background: var(--c1); border: none; white-space: nowrap;}
  .DateRangePickerInput .DateInput {background: transparent; width: 90px; vertical-align: unset;}
    .DateRangePickerInput .DateInput .DateInput_input {color: var(--c6); background: transparent; font-size: 12px; padding: 0 12px; cursor: pointer; height: 20px; vertical-align: top;}
    .DateRangePickerInput .DateInput .DateInput_input.DateInput_input__focused {border-color: var(--brand);}
        
  .DateRangePickerInput .DateRangePickerInput_arrow {vertical-align: top; line-height: 10px;}
  .DateRangePickerInput .DateRangePickerInput_arrow_svg {fill: var(--c3); height: 20px; width: 20px;}
.DateRangePickerInput_clearDates {padding: 0; margin: 0; margin-right: 5px; margin-top: -1px;}
.DateRangePickerInput_clearDates:hover {background: transparent;}
  .DateRangePickerInput_clearDates_svg {width: 10px; height: 10px;}

/* NOTIFICATION CENTER */
#exchange.NotificationCenter {right: 0; border-left: 2px solid var(--c5); -webkit-transform: translateX(100%); transform: translateX(100%); top: 50px;}
  #exchange.NotificationCenter .head {text-align: right;}
  #exchange.NotificationCenter .toggle {-webkit-transform: translateX(-100%);transform: translateX(-100%); left: 0; right: unset;}
  #exchange.NotificationCenter.showing .toggle {-webkit-transform: none;transform: none;}
  #exchange.NotificationCenter .new {left: 0; -webkit-transform: translateX(-100%); transform: translateX(-100%);}

/* BUTTONS */
#exchange .btn-primary {background-color: var(--c8); border-color: var(--c8); color: var(--c4); background-image: none!important;}
#exchange .btn-primary:hover, #exchange .btn-primary:focus {background-color: var(--c8); border-color: var(--c8); color: var(--c1);}
#exchange .btn-primary.active, #exchange .btn-primary.active:hover, #exchange .btn-primary:active, #exchange .btn-primary:active:hover {
  background-color: var(--c8); border-color: var(--c8); color: var(--brand);
}
#exchange .btn-primary[disabled], #exchange .btn-primary[disabled]:hover {background-color: var(--c8); border-color: var(--c8); color: var(--c7);}

#exchange .uiBodyHead .btn, #exchange .tableActions .btn {font-size: 12px; height: 20px; line-height: 18px;}

/* FORMS */
#exchange .uiBodyHead .form-control, #exchange .tableActions .form-control {font-size: 12px; height: 20px; padding: 0 12px; line-height: 18px;}
#exchange .uiBodyHead .input-group .form-control, #exchange .tableActions .input-group .form-control {height: 18px;}
#exchange .uiBodyHead .input-group-addon, #exchange .tableActions .input-group-addon {font-size: 12px;}
#exchange .uiBodyHead .select .fa, #exchange .tableActions .select .fa {top: 0.5px; right: 5px;}
#exchange .uiBodyHead .checkbox, #exchange .tableActions .checkbox {margin: 0 0 0 30px; color: var(--c7);}

#exchange .Funds .addNewCurrency {margin: 10px;}
#exchange .mobile.container-fluid {height: calc(100vh - 40px); background: var(--c8); color: var(--c1); font-family: var(--font);}

  #exchange .mobile > .tabs {height: 30px;}
    #exchange .mobile > .tabs > div {text-transform: uppercase; text-align: center; padding: 5px 0; color: var(--c7); white-space: nowrap; background: var(--c3);}
    #exchange .mobile > .tabs > div.active {color: var(--c8); background: var(--brand); font-weight: 600;}

  #exchange .mobile > .content {height: calc(100% - 30px); display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; background-color: var(--c1); color: var(--c7);}
    #exchange .mobile > .content > div {padding: 0;}
    #exchange .mobile > .content > .CustomCol > div {padding: 0;}
    #exchange .mobile > .content .tabs {margin: 2px;}
    #exchange .mobile > .content .tabs > div {text-align: center; padding: 0 2.5px;}
     #exchange .mobile > .content .tabs > div > span {font-size: 12px; text-transform: uppercase; padding: 4px; color: var(--c5); display: inline-block; white-space: nowrap; width: 100%; border: 1px solid var(--c5);}
     #exchange .mobile > .content .tabs > div.active > span {color: var(--c8); background: var(--c5);}

  #exchange .mobile h4 {color: var(--c8); text-transform: uppercase; margin: 0; font-size: 14px; background: var(--c3); height: 30px;}
    #exchange .mobile h4 span {display: inline-block; padding: 7.5px 10px;}
    #exchange .mobile h4 span.clickable {cursor: pointer; text-align: center;}
    #exchange .mobile h4 span.active {background: var(--c4);}

  #exchange .mobile .activePair {height: auto;}
    #exchange .mobile .activePair > div {width: 25%;}
  #exchange .mobile .pair-selection {
    position: absolute; -webkit-transform: translate(0,-100%); transform: translate(0,-100%); transition: -webkit-transform 1s ease; transition: transform 1s ease; transition: transform 1s ease, -webkit-transform 1s ease;
    z-index: 50;
    background-color: var(--c1);
    width: 100%;
    overflow: auto;
    padding: 0;
    top: 40px;
    height: 100%;
  }
  #exchange .mobile .pair-selection.active { -webkit-transform: translate(0,0); transform: translate(0,0); }
    #exchange .mobile .pair-selection .tableActions {margin: 0; padding: 5px 0;}
    #exchange .mobile .pair-selection table {margin-top: 5px;}

  #exchange .mobile .chartComponent {min-height: 35%; overflow: hidden; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column;}
  #exchange .mobile .chartComponent .DepthChart {-webkit-flex-grow: 1;flex-grow: 1;}

  #exchange .mobile .tradeHistoryComponent {-webkit-flex-grow: 1;flex-grow: 1; overflow: hidden;}
    #exchange .mobile .tradeHistoryComponent .segment {overflow: auto;}

  #exchange .mobile .orderFormComponent {background: var(--c2); position: relative;}
    #exchange .mobile .orderFormComponent .uiBodyHead {position: absolute; right: 5px; top: 0; overflow: hidden; padding: 0;}
      #exchange .mobile .orderFormComponent .uiBodyHead .tabs > div {padding: 0;}
      #exchange .mobile .orderFormComponent .uiBodyHead .tabs span {padding: 2px 25px !important;}
    #exchange .mobile .orderFormComponent .orderForm {overflow: hidden; height: auto;}
      #exchange .mobile .orderFormComponent .orderForm .fields {-webkit-flex-grow: unset;flex-grow: unset;}
    #exchange .mobile .orderFormComponent .container-fluid {margin: 0; padding: 4px 10px; -webkit-flex: unset; flex: unset;}
    #exchange .mobile .orderFormComponent .form-group {margin: 0 0 4px;}
      #exchange .mobile .orderFormComponent .form-group label {padding: 2px 0;}
      #exchange .mobile .orderFormComponent .form-group .form-control {height: 24px;}
      #exchange .mobile .orderFormComponent .form-group .input-group-addon {padding: 5px 12px;}
    #exchange .mobile .orderFormComponent .form-group button.btn {margin: 0;}

  #exchange .mobile .orderBookComponent {-webkit-flex-grow: 1;flex-grow: 1; overflow: hidden; position: relative;}
    #exchange .mobile .orderBookComponent .uiBodyHead {position: absolute; right: 5px; top: 0;}
    #exchange .mobile .orderBookComponent .segment {display: -webkit-flex;display: flex; -webkit-flex-direction: column; flex-direction: column;}
    #exchange .mobile .orderBookComponent .row.book {overflow: auto; padding: 0 16px;}
      #exchange .mobile .orderBookComponent .row.book > div {padding: 0 2px;}
    #exchange .mobile .orderBookComponent table {background: var(--c1);}
    #exchange .mobile .orderBookComponent .midMarketPrice {padding: 0 10px; background: var(--c3);}
      #exchange .mobile .orderBookComponent .midMarketPrice .usdEquivalent {float: right;}
      #exchange .mobile .orderBookComponent .midMarketPrice .value {white-space: nowrap;}
        #exchange .mobile .orderBookComponent .midMarketPrice .value .fa {margin-left: 5px;}

  #exchange .mobile .ordersComponent {-webkit-flex-grow: 1;flex-grow: 1; overflow: hidden;}
    #exchange .mobile .ordersComponent .segment {overflow: auto;}

  #exchange .mobile .fundsComponent {}
    #exchange .mobile .fundsComponent .item {height: 30px; cursor: pointer;}
      #exchange .mobile .fundsComponent .item td:first-child {padding-left: 25px;}
    #exchange .mobile .fundsComponent .fund-actions {border-spacing: 0 3px; background: var(--c7);}
      #exchange .mobile .fundsComponent .fund-actions .row {margin: 0;}
      #exchange .mobile .fundsComponent .fund-actions td {padding: 0;}
      #exchange .mobile .fundsComponent .fund-actions button {margin: 0;}
      #exchange .mobile .fundsComponent .addNewCurrency {margin: 0 5px;}
  
  #exchange .mobile .uiBodyHead, #exchange .mobile .tableActions {padding: 0 0 2px; white-space: nowrap; overflow: hidden; overflow-x: auto; background-color: var(--c3);}
    #exchange .mobile .uiBodyHead .form-group {margin: 2.5px;}
      #exchange .mobile .uiBodyHead .form-control {border: 1px solid var(--c4); color: var(--c7);}
      #exchange .mobile .uiBodyHead .uiButton {border: 1px solid var(--c4); color: var(--c7); margin: 2.5px; padding: 3.5px 10px;}
      #exchange .mobile .uiBodyHead button.btn, #exchange .mobile .uiBodyHead .DateRangePicker {margin: 3px 5px;}
      #exchange .mobile .uiBodyHead .select {}
        #exchange .mobile .uiBodyHead .select .form-control {border: none;}
        #exchange .mobile .uiBodyHead .select .fa {color: var(--c7); right: 2px;}

  #exchange .mobile .tableActions {margin: 10px 10px 0; padding: 0;}
    #exchange .mobile .tableActions label {color: var(--c5);}
    #exchange .mobile .tableActions .input-group {border: 1px solid var(--c5); width: 40%; float: left;}
    #exchange .mobile .tableActions .checkbox {float: left; margin: 2px 10px;}

  #exchange .mobile table {width: 100%; table-layout: fixed;}
    #exchange .mobile table th {font-size: 14px; padding-top: 0;}
    #exchange .mobile table th.active {color: var(--c8);}
      #exchange .mobile table th .fa {display: none; color: var(--c8);}
      #exchange .mobile table th.active .fa {display: inline-block;}
      #exchange .mobile table tr {background-color: transparent;}
  #exchange .mobile .Funds table {border-collapse: separate; border-spacing: 0 5px;}
  #exchange .mobile .Funds tbody tr {background: var(--c3);}
  #exchange .mobile .Funds .fund-actions .transferTypes {display: block;}

.mobileTable {}
  .mobileTableHead {white-space: nowrap; overflow: auto;}
    .mobileTableHead > div {display: inline-block; padding: 5px 10px; color: var(--c5); cursor: pointer;}
    .mobileTableHead > div.active {color: var(--c8);}
      .mobileTableHead > div .fa {margin-left: 10px;}
  .mobileTable > .row {margin: 0 0 10px; background-color: var(--c3); padding: 5px 0; font-size: 12px; white-space: nowrap;}
    .mobileTable > .row .title {color: var(--c5);}
    .mobileTable > .row .value {}
  .mobileTable .btn, {font-size: 15px; padding: 4px; background: var(--c4); color: var(--c8); margin: 8px 0 0;}
#Coinvenience {min-height: calc(100vh - 348px);}
#Coinvenience h3 {font-weight: 100;}
#CoinvenienceBanner {background: linear-gradient(to bottom, var(--c8) 0%,var(--c8) 15%,var(--brand) 15%,var(--brand) 53%,var(--c8) 53%,var(--c8) 60%,var(--c1) 60%,var(--c1) 69%,var(--c8) 69%,var(--c8) 77%,var(--brand) 77%,var(--brand) 85%,var(--c8) 85%);}
  #CoinvenienceBanner h1 {background: var(--c8); display: inline-block; padding: 5px 40px; color: var(--c1); font-size: 27px;}
    #CoinvenienceBanner h1 img {height: 30px; margin-right: 20px; vertical-align: top;}

  #Coinvenience .box {box-shadow: var(--shadow); padding: 20px;}

@media (max-width: 990px) {
  #CoinvenienceBanner h1 {font-size: 25px; vertical-align: middle;}
  #CoinvenienceBanner h1 img {display: none;}
}
#Bfc h2 {text-align: center; margin: 60px 0 40px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
  #Bfc h2 hr {-webkit-flex: 1 1;flex: 1 1;}
  #Bfc h2 span {padding: 0 15px;}
#Bfc p {margin: 15px 0;}

#Bfc .bg {background-image: url('/img/icons/bfccoin.png');}

#Bfc .icoEptTable {border: 1px solid var(--c5); border-bottom: none; margin: 15px 0;}
  #Bfc .icoEptTable > div {border-bottom: 1px solid var(--c5);}
    #Bfc .icoEptTable .name {font-weight: 600; padding: 0 10px; border-right: 1px solid var(--c5); background-color: var(--c2); line-height: 16px; height: 41px; display:-webkit-flex; display:flex; -webkit-align-items:center; align-items:center;}
    #Bfc .icoEptTable .value {-webkit-justify-content: center;justify-content: center; padding: 0 10px; line-height: 16px; height: 41px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; text-align: center;}

#Articles.articles .bg {background-image: url('/img/icons/articles.png');}
#Articles.news .bg {background-image: url('/img/icons/news.png');}
#Contact {}
  #Contact .btn {width: 300px;}
.socialMedia {}
  .socialMedia i {font-size: 20px; float: left;}
  .socialMedia .btn {vertical-align: middle; margin-bottom: 10px;}

#Contact .bg {background-image: url('/img/icons/contact.png');}
#Faq {}
  #Faq h4 {color: var(--c4); font-family: var(--font); margin: 15px 0;}
  #Faq img {max-width: 100%;}
  #Faq .row {margin-top: 30px;}
  #Faq .category {
    padding: 5px 15px;
    cursor: pointer;
    color: var(--c8);
    font-size: 15px;
    font-weight: 400;
    background-color: var(--c1);
    border-radius: 5px;
    box-shadow: var(--small-shadow);
    margin: 10px 0;
    border: 1px solid var(--c2);
  }
  #Faq .category:hover {background-color: var(--c2);}
  #Faq .category.active {color: var(--brand); background-color: var(--c2);}
  #Faq .category.inactive {color: var(--c6);}
  #Faq .question {padding: 5px 0;}
    #Faq .text {color: var(--c8); cursor: pointer; font-size: 15px; font-weight: 600; color: var(--c6);}
      #Faq .text .fa {margin-left: 10px;}
    #Faq .answer {margin: 5px 0 10px; font-family: var(--font); font-weight: 300; padding-left: 20px; color: var(--c5);}
  #Faq .bg {background-image: url('/img/icons/faq.png');}
#ApiDocumentation {}
  #ApiDocumentation h2 {margin: 20px 0; font-weight: 400;}
  #ApiDocumentation h3 {font-family: var(--font2); margin: 10px 0; font-size: 22px;}
  #ApiDocumentation h4 {margin: 15px 0;}
  #ApiDocumentation p {margin: 5px 0;}
  #ApiDocumentation ul {padding-left: 40px;}
    #ApiDocumentation li {margin-bottom: 5px;}
  #ApiDocumentation .index {position: fixed; margin-top: 20px; margin-left: -20px; border-right: 2px solid var(--c8); min-height: calc(100vh - 100px); padding-right: 20px;}
    #ApiDocumentation .indexItem {margin-left: 20px; margin-bottom: 5px;}
    #ApiDocumentation .indexItem a {text-decoration: none; color: inherit; font-weight: 600;}
    #ApiDocumentation .indexItem a:hover {color: var(--brand);}
#Jobs .top {position: relative; padding-top: 15vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#255389+0,388bec+50,ffffff+100 */
    background: rgb(37,83,137); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
#Jobs .top .bg {background-image: url('/img/texture2.png'); position: absolute; width: 100%; height: 100vh; opacity: 0.1; top: 0;}

#Jobs .imgTextCombo {margin-bottom: 50px; display: -webkit-flex; display: flex;}
#Jobs a {text-decoration: none;}
#Jobs a .imgTextCombo {color: var(--c8); transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s;}
#Jobs a .imgTextCombo:hover {background-color: var(--c2); -webkit-transform: scale(1.05); transform: scale(1.05);}
#Jobs .imgTextCombo.vertical {-webkit-flex-direction: column;flex-direction: column;}
#Jobs .imgTextCombo .image {position: relative; transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s; -webkit-transform-origin: 0 50%; transform-origin: 0 50%;}
#Jobs .imgTextCombo .text {padding: 0 15px;}
#Jobs .imgTextCombo.vertical .text {padding: 20px 20px 0;}
#Jobs .imgTextCombo h3 {margin-bottom: 10px;}

#Jobs .shadowBox {box-shadow: var(--small-shadow); padding: 20px 0;}
#Jobs .shadowBox .image {padding-left: 20px;}
#Jobs .shadowBox .image:hover {-webkit-transform: none;transform: none;}
#Jobs .shadowBox.vertical {padding-top: 0;}
#Jobs .shadowBox.vertical img {width: 100%;}

#Jobs .center {text-align: center;}

#Jobs .banner {height: 100px; text-align: center; top: 40px; width: 100vw; padding: 10px 0 0; }
#Jobs .banner img {z-index: 10; max-height: 100%; max-width: 90%;}


#Job1 .top {position: relative; padding-top: 15vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#255389+0,388bec+50,ffffff+100 */
    background: rgb(37,83,137); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
#Job1 .top .bg {background-image: url('/img/texture2.png'); position: absolute; width: 100%; height: 100vh; opacity: 0.1; top: 0;}


#Job1 .center {text-align: center;}

#Job1 .banner {height: 100px; text-align: center; top: 40px; width: 100vw; padding: 10px 0 0; }
#Job1 .banner img {z-index: 10; max-height: 100%; max-width: 90%;}
#Job1Application .top {position: relative; padding-top: 15vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#255389+0,388bec+50,ffffff+100 */
    background: rgb(37,83,137); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
#Job1Application .top .bg {background-image: url('/img/texture2.png'); position: absolute; width: 100%; height: 100vh; opacity: 0.1; top: 0;}


#Job1Application .center {text-align: center;}

#Job1Application .banner {height: 100px; text-align: center; top: 40px; width: 100vw; padding: 10px 0 0; }
#Job1Application .banner img {z-index: 10; max-height: 100%; max-width: 90%;}

#Job1Application .shadowBox {box-shadow: var(--small-shadow); padding: 20px 20px; background-color: var(--c2)}
#Job1Application .shadowBox .image {padding-left: 20px;}
#Job1Application .shadowBox .image:hover {-webkit-transform: none;transform: none;}
#Job1Application .shadowBox.vertical {padding-top: 0;}
#Job1Application .shadowBox.vertical img {width: 100%;}

  #Job1Application .h4 {font-weight: 500; margin-bottom: 18px; font-size: 20px;}
  #Job1Application .input, #Job1Application .select {max-width: 500px;}
  #Job1Application .img.illust {margin: 15px 22px; max-width: 150px; max-height: 150px;}
  #Job1Application .checkbox {display: inline-block; font-size: 19px; margin: 10px 20px 20px;}
    #Job1Application .checkbox input {margin: 5px -20px;} 
  #Job1Application .dateOfBirth .select {width: 160px; display: inline-block; margin-right: 10px;}
  #Job1Application .document {
    display: inline-block;
    margin: 20px;
    position: relative;
  }
    #Job1Application .document img { height: 125px; }
    #Job1Application .document span {color: var(--brand);}
    #Job1Application .fileUpload span {
      background-color: var(--c4);
      color: var(--c1);
      font-family: var(--font2);
      padding: 5px 0;
      width: 200px;
      display: block;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 20px;
      vertical-align: middle;
      display: inline-block;
    }
    #Job1Application .fileUpload span:hover { background-color: var(--c5); }
    #Job1Application .fileUpload input {display: none;}
    #Job1Application .button {border-radius: 5px;}
#Job2 .top {position: relative; padding-top: 15vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#255389+0,388bec+50,ffffff+100 */
    background: rgb(37,83,137); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
#Job2 .top .bg {background-image: url('/img/texture2.png'); position: absolute; width: 100%; height: 100vh; opacity: 0.1; top: 0;}


#Job2 .center {text-align: center;}

#Job2 .banner {height: 100px; text-align: center; top: 40px; width: 100vw; padding: 10px 0 0; }
#Job2 .banner img {z-index: 10; max-height: 100%; max-width: 90%;}
#Job2Application .top {position: relative; padding-top: 15vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#255389+0,388bec+50,ffffff+100 */
    background: rgb(37,83,137); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }
#Job2Application .top .bg {background-image: url('/img/texture2.png'); position: absolute; width: 100%; height: 100vh; opacity: 0.1; top: 0;}


#Job2Application .center {text-align: center;}

#Job2Application .banner {height: 100px; text-align: center; top: 40px; width: 100vw; padding: 10px 0 0; }
#Job2Application .banner img {z-index: 10; max-height: 100%; max-width: 90%;}

#Job2Application .shadowBox {box-shadow: var(--small-shadow); padding: 20px 20px; background-color: var(--c2)}
#Job2Application .shadowBox .image {padding-left: 20px;}
#Job2Application .shadowBox .image:hover {-webkit-transform: none;transform: none;}
#Job2Application .shadowBox.vertical {padding-top: 0;}
#Job2Application .shadowBox.vertical img {width: 100%;}

  #Job2Application .h4 {font-weight: 500; margin-bottom: 18px; font-size: 20px;}
  #Job2Application .input, #Job2Application .select {max-width: 500px;}
  #Job2Application .img.illust {margin: 15px 22px; max-width: 150px; max-height: 150px;}
  #Job2Application .checkbox {display: inline-block; font-size: 19px; margin: 10px 20px 20px;}
    #Job2Application .checkbox input {margin: 5px -20px;} 
  #Job2Application .dateOfBirth .select {width: 160px; display: inline-block; margin-right: 10px;}
  #Job2Application .document {
    display: inline-block;
    margin: 20px;
    position: relative;
  }
    #Job2Application .document img { height: 125px; }
    #Job2Application .document span {color: var(--brand);}
    #Job2Application .fileUpload span {
      background-color: var(--c4);
      color: var(--c1);
      font-family: var(--font2);
      padding: 5px 0;
      width: 200px;
      display: block;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 20px;
      vertical-align: middle;
      display: inline-block;
    }
    #Job2Application .fileUpload span:hover { background-color: var(--c5); }
    #Job2Application .fileUpload input {display: none;}
    #Job2Application .button {border-radius: 5px;}
.email { font-family: 'Exo', sans-serif; }
  .email .cont {max-width: 600px; overflow: hidden;}
  .email .logo {background-color: #111111; text-align: left;}
    .email .logo img {height: 25px; margin: 10px 30px;}

#CoinvenienceEmail .grad {
  background-color: #4CBF00; padding: 20px 30px; text-align: left; color: white; font-weight: 300; font-size: 46px;
  background: #4CBF00; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,104,55,1) 0%,rgba(76,191,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006837', endColorstr='#4cbf00',GradientType=1 ); /* IE6-9 */
}
  #CoinvenienceEmail .grad > img {width: 570px; margin-bottom: -75px;}
  #CoinvenienceEmail .grad .introducing {font-size: 32px; color: #111; font-weight: 400;}
  #CoinvenienceEmail .grad .btn-cont {text-align: center; font-size: 14px; font-weight: 600; margin-top: 18px;}
    #CoinvenienceEmail .grad .btn-cont a {color: black; background-color: white; border-radius: 20px; text-decoration: none; padding: 8px 25px; display: inline-block;}
      #CoinvenienceEmail .grad .btn-cont a img {height: 24px; vertical-align: bottom;}
  #CoinvenienceEmail .grad .freeBfc {text-align: center;border: 1px solid white;font-size: 20px;margin-top: 30px;padding: 10px;}

.email .mainImg {width: 100%; height: 180px; background-size: contain; background-repeat: no-repeat;}

.email .bodyCont {padding: 40px 30px; text-align: left;}
  .email .bodyCont span {font-size: 20px;}
  .email .bodyCont p {margin: 15px 0;}

.email .foot {background-color: #333333; text-align: center; padding: 5px 30px; color: white; font-size: 12px;}
  .email .foot .foot-left {float: left; color: #777; font-size: 9px; margin: 2px 0;}
  .email .foot .foot-right {float: right;}
  .email .foot img {height: 16px; vertical-align: bottom; margin: 0 4px;}
@media (max-width: 550px) {
  .email .foot span {display: block; float: none; white-space: nowrap; margin: 5px 0;}
}
@media (max-width: 400px) {
  #CoinvenienceEmail .grad {font-size: 38px;}
  #CoinvenienceEmail .grad .introducing {font-size: 26px;}
}
:root {
  --brand: #4CBF00;
  --bull: #1CB787;
  --alert: #FFCE00;
  --bear: #FF41A1;
  --bluefinity: #5396f7;
  --danger: #FF0000;

  --c0: rgba(255,255,255,0.75);
  --c1: #FFFFFF;
  --c2: #F0F0F0;
  --c3: #BBBBBB;
  --c4: #999999;
  --c5: #777777;
  --c6: #555555;
  --c7: #333333;
  --c8: #111111;
  
  --small-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25);
  --shadow: 2px 2px 5px 0px rgba(31,31,31,0.25);
  --big-shadow: 0px 4px 4px 4px rgba(31,31,31,0.25);

  --font: 'Roboto', sans-serif;
  --font2: 'Exo', sans-serif;
}

button.mercadopago-button {
  background-color:#4CBF00;
  padding: 0 30px;
  font-weight: 600;
  height: 30px;
  font-family: 'Exo', sans-serif;
  border-radius: 5px;
  margin-left: 15px;
}

/* BROWSER STYLES */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

/* ANIMATIONS */
  /* CRAZY SPIN PARTY MODE ANIMATION */
    @-webkit-keyframes spin {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
        from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
        to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
    }

    /* EARTHQUAKE MODE ANIMATION */
    @-webkit-keyframes earthquake {
        from { -webkit-transform: translateX(0px); }
        to { -webkit-transform: translateX(2px); }
    }
    @keyframes earthquake {
        from {-webkit-transform: translateX(0px);transform: translateX(0px);}
        to {-webkit-transform: translateX(2px);transform: translateX(2px);}
    }

  /* ATTENTION GLOW ANIMATION */
    @-webkit-keyframes attention-glow {
        from { -webkit-box-shadow: 0px 0px 30px 0px #111111; -webkit-box-shadow: 0px 0px 30px 0px var(--c8); }
        to { -webkit-box-shadow: none; }
    }
    @keyframes attention-glow {
        from { box-shadow: 0px 0px 30px 0px #111111; box-shadow: 0px 0px 30px 0px var(--c8); }
        to { box-shadow: none; }
    }

    /* BEATING */
    @-webkit-keyframes beating {
        from { -webkit-transform: scale(1); transform: scale(1); }
        to   { -webkit-transform: scale(1.5); transform: scale(1.5); }
    }
    @keyframes beating {
        from { -webkit-transform: scale(1); transform: scale(1); }
        to   { -webkit-transform: scale(1.5); transform: scale(1.5); }
    }

    .rotate {transition: -webkit-transform 0.25s;transition: transform 0.25s;transition: transform 0.25s, -webkit-transform 0.25s;}
    .rotate.rotate-left {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
    .rotate.rotate-right {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
    .rotate.upsideDown {-webkit-transform: rotate(-180deg);transform: rotate(-180deg);}

    .beat {-webkit-animation-name: beating;animation-name: beating; -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-iteration-count:infinite; animation-iteration-count:infinite; -webkit-animation-fill-mode: both; animation-fill-mode: both;}

    .spinning {
      -webkit-animation-name: spin;
              animation-name: spin;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
    }

  /* .pink body, .pink button, .pink div, .pink span, .pink a, .pink img, .pink tr, .pink td, .pink p {
      animation-name: spin;
      animation-duration: 10s;
      animation-iteration-count:infinite;
      animation-timing-function: linear;
  } */

  /* button, span, a, img, tr, td, p, h1, h2, h3, h4, h5, h6, .uiBody, .uiContent {
      animation-name: spin;
      animation-duration: 0.025s;
      animation-iteration-count:infinite;
      animation-timing-function: linear;
  } */

/* COLOR CLASSES */
  .white {color: #FFFFFF;color: var(--c1); border-color: #FFFFFF; border-color: var(--c1);}
  .red {color: #FF0000!important;color: var(--danger)!important; border-color: #FF0000; border-color: var(--danger);}
  .blue {color: #5396f7!important;color: var(--bluefinity)!important; border-color: #5396f7; border-color: var(--bluefinity);}
  .bull {color: #1CB787!important;color: var(--bull)!important; border-color: #1CB787; border-color: var(--bull);}
  .bear {color: #FF41A1!important;color: var(--bear)!important; border-color: #FF41A1; border-color: var(--bear);}
  .green {color: #4CBF00!important;color: var(--brand)!important; border-color: #4CBF00; border-color: var(--brand);}
  .yellow {color: #FFCE00;color: var(--alert); border-color: #FFCE00; border-color: var(--alert);}
  .grey1 {color: #333333;color: var(--c7); border-color: #333333; border-color: var(--c7);}
  .grey2 {color: #555555;color: var(--c6); border-color: #555555; border-color: var(--c6);}
  .grey3 {color: #777777;color: var(--c5); border-color: #777777; border-color: var(--c5);}
  .grey4 {color: #999999;color: var(--c4); border-color: #999999; border-color: var(--c4);}
  .grey5 {color: #BBBBBB;color: var(--c3); border-color: #BBBBBB; border-color: var(--c3);}
  .grey6 {color: #F0F0F0;color: var(--c2); border-color: #F0F0F0; border-color: var(--c2);}
  
  .CLEAR, .PENDING_REVIEW {color: #1CB787;color: var(--bull); border-color: #1CB787; border-color: var(--bull);}
  .VERIFIED {color: #4CBF00;color: var(--brand); border-color: #4CBF00; border-color: var(--brand);}
  .REVIEW {color: #BBBBBB;color: var(--c3); border-color: #BBBBBB; border-color: var(--c3);}
  .COLLECT {color: #FFCE00;color: var(--alert); border-color: #FFCE00; border-color: var(--alert);}
  .NOT_ALLOWED, .REJECTED, .DELETED {color: #FF0000;color: var(--danger); border-color: #FF0000; border-color: var(--danger);}

/* GENERAL CLASSES */
  .left {float: left !important; margin: 0 auto 0 0 !important;}
  .right {float: right !important; margin: 0 0 0 auto !important;}
  .fill {-webkit-flex-grow: 1;flex-grow: 1;}
  .alignY {display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;}
  .resizing, .scrolling, .dragging {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  .clickable {cursor: pointer;}
  .shadowed {box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25);box-shadow: var(--small-shadow);}
  .rounded {border-radius: 5px;}

/* TEXT TAG GENERAL STYLES */
  h1 {font-family: 'Exo', sans-serif;font-family: var(--font2); font-weight: 300; font-size: 36px; margin: 0;}
  h2 {font-family: 'Roboto', sans-serif;font-family: var(--font); font-weight: 400; font-size: 28px; margin: 0;}
  h3 {font-family: 'Exo', sans-serif;font-family: var(--font2); font-weight: 300; font-size: 24px; margin: 0 0 20px;}
  h4 {font-family: 'Roboto', sans-serif;font-family: var(--font); font-weight: 300; font-size: 18px; margin: 0;}
  h4 p.tag {display: inline-block; width: auto; margin: 0 15px !important; padding: 0 30px; font-weight: 300!important;}
  h5 {font-family: 'Exo', sans-serif;font-family: var(--font2); font-weight: 400; font-size: 14px; margin: 0;}
  h6 {font-family: 'Roboto', sans-serif;font-family: var(--font); font-weight: 400; font-size: 12px; margin: 0;}
  p {margin: 0; font-weight: 300;}
  p.tag {margin: 0 !important; padding: 4px; width: 100%;}
    p .fa {font-size: 18px; margin-right: 10px; vertical-align: top;}
  a {color: #4CBF00;color: var(--brand); text-decoration: underline; cursor: pointer;}

/* APP */
  .App {font-family: 'Roboto', sans-serif;font-family: var(--font); position: relative; color: #555555; color: var(--c6); font-weight: 400; font-size: 14px; -webkit-font-smoothing: auto; background-color: #FFFFFF; background-color: var(--c1); min-height: 100vh;}
  /* .App.mobile {padding-top: 40px;} */
    .App > .container {padding: 20px;}
    .App.mobile > .container {}
      .App > .container > h1 {margin: 5px 0 15px;}

/* HEADER */
  .headerSpacer {height: 40px;}
  .App-header {height: 40px; /* overflow: hidden; */ background-color: black; position: fixed; width: 100%; z-index: 100; top: 0;}
  .App-header.mobile {height: 40px; padding: 2px 10px; position: fixed; z-index: 101; width: 100vw; background: #FFFFFF; background: var(--c1); top: 0;}
    .App-header .container, .App-header .container .row, .App-header .container .row > div {height: 100%;}
    .App-logo {height: 100%; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; pointer-events: all;}
      .App-logo img {height: 75%;}
      #BinfinityLogo {height: 25px;}
        #BinfinityLogo polygon {fill: #4CBF00;fill: var(--brand);}
        #BinfinityLogo path {fill: #111111;fill: var(--c8);}
    .App-header .links {height: 100%; font-family: 'Exo', sans-serif; font-family: var(--font2); font-weight: 600; white-space: nowrap;}
      .App-header .links a {height: 100%; display: -webkit-inline-flex; display: inline-flex; -webkit-align-items: center; align-items: center; text-decoration: none; padding: 0 20px; color: #555555; color: var(--c6);  position: relative;}
      .App-header .links a:hover, .App-header .links a:focus {color: #111111;color: var(--c8);}
      .App-header .links a:active, .App-header .links a:active:hover {color: #4CBF00;color: var(--brand);}
      .App-header .links a.active, .App-header .links a.active:hover {background-color: #4CBF00;background-color: var(--brand); color: #FFFFFF; color: var(--c1);}
        .App-header .links a i {position: absolute; left: 10%; font-size: 5px;}
    .App-header .actions {height: 100%; float: right; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
      .App-header .actions button {margin: 0 5px;}
      .App-header .actions .connection {font-size: 20px; padding: 0 10px;}
    .App-header.mobile button.btn {font-size: 14px; padding: 6px 10px; box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25); box-shadow: var(--small-shadow); border: none; margin-left: 8px; color: #111111; color: var(--c8);}
    .App-header.mobile button.btn.active {background: #4CBF00;background: var(--brand);}

  .App-header.alt.animated {transition: all 1s; transition-property: background-color, -webkit-transform; transition-property: background-color, transform; transition-property: background-color, transform, -webkit-transform; background-color: transparent;}
  .App-header.alt.animated .container {transition-delay: 0.25s; transition-property: -webkit-transform; transition-property: transform; transition-property: transform, -webkit-transform;}
  .App-header.alt.animated #BinfinityLogo path {fill: #FFFFFF;fill: var(--c1); transition: fill 1s;}
  .App-header.alt.animated .btn {color: #FFFFFF;color: var(--c1); transition: color 1s, -webkit-transform 0.5s; transition: color 1s, transform 0.5s; transition: color 1s, transform 0.5s, -webkit-transform 0.5s;}
  .App-header.alt.animated.secondState {background-color: #FFFFFF;background-color: var(--c1); -webkit-transform: rotateX(180deg); transform: rotateX(180deg);}
  .App-header.alt.animated.secondState .container {-webkit-transform: rotateX(180deg);transform: rotateX(180deg);}
  .App-header.alt.animated.secondState #BinfinityLogo path {fill: #111111;fill: var(--c8);}
  .App-header.alt.animated.secondState .btn {color: #111111;color: var(--c8);}

  .App-header.alt {background-color: #FFFFFF;background-color: var(--c1);}
  .App-header.alt button.btn.menuBtn {margin-left: 0; padding-left: 5px; box-shadow: none!important; outline: none!important;}
  .App-header.alt .menuBtn div {transition: -webkit-transform 0.5s;transition: transform 0.5s;transition: transform 0.5s, -webkit-transform 0.5s; -webkit-transform-origin: 50% 50%; transform-origin: 50% 50%;}
  .App-header.alt .menuBtn.active div {-webkit-transform: rotateX(180deg);transform: rotateX(180deg);}
    .App-header.alt .menuBtn i {transition: opacity; transition-delay: 0.25s;}
    .App-header.alt .menuBtn i.fa-bars {}
    .App-header.alt .menuBtn.active i.fa-bars {opacity: 0;}
    .App-header.alt .menuBtn i.fa-times {color: #111111;color: var(--c8); opacity: 1; position: absolute; left: 0; opacity: 0; top: 2px; font-size: 18px;}
    .App-header.alt .menuBtn.active i.fa-times {opacity: 1;}
    .App-header.alt .menuBtn > div {display: inline-block;}
    .App-header.alt .menuBtn > span {margin-left: 5px; display: inline-block; margin-left: 8px;}

  .App-header.alt .logo {height: 100%; display: -webkit-flex; display: flex; position: absolute; left: 0; right: 0; -webkit-justify-content: center; justify-content: center; pointer-events: none;}
  .App-header.alt button.btn {background-color: transparent; color: #111111; color: var(--c8); font-weight: 300; border: 0; border-radius: 5px; padding: 0 20px; transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s;}
  .App-header.alt button.btn:hover {-webkit-transform: scale(1.15);transform: scale(1.15);}
    .App-header.alt button.btn.register, .App-header.alt button.btn.myPage {background-color: #4CBF00;background-color: var(--brand); box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25); box-shadow: var(--small-shadow);}
    .App-header.alt button.btn.signOut {background-color: #FF41A1;background-color: var(--bear); box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25); box-shadow: var(--small-shadow);}

/* NAVBAR */
  .App-navbar {background-color: #777777;background-color: var(--c5); font-family: 'Exo', sans-serif; font-family: var(--font2);}
    .App-navbar a {height: 40px; display: -webkit-inline-flex; display: inline-flex; -webkit-align-items: center; align-items: center; text-decoration: none; padding: 0 20px; color: #FFFFFF; color: var(--c1);}
    .App-navbar a.disabled {color: #BBBBBB;color: var(--c3);}
    .App-navbar a:hover, .App-navbar a:focus {background-color: #333333;background-color: var(--c7);}
    .App-navbar a.disabled:hover, .App-navbar a.disabled:focus {background-color: transparent;}
    .App-navbar a:active, .App-navbar a:active:hover {background-color: #333333;background-color: var(--c7); color: #4CBF00; color: var(--brand);}
    .App-navbar a.disabled:active, .App-navbar a.disabled:active:hover {background-color: transparent; color: #BBBBBB; color: var(--c3);}
    .App-navbar a.active, .App-navbar a.active:hover {background-color: #4CBF00;background-color: var(--brand); color: #FFFFFF; color: var(--c1);}

/* FOOTER */
  .App-footer {padding: 50px 0 10px; background-color: #FFFFFF; background-color: var(--c1); color: #333333; color: var(--c7);}
  .App-footer.alt {padding: 10px 0;}
    .App-footer .logo {height: 35px;}
    .App-footer .logoVQF {height: 50px; margin-left: 10px;}
    .App-footer h5 {margin-bottom: 10px; color: #555555; color: var(--c6); font-size: 16px;}
    .App-footer h6 {color: #BBBBBB;color: var(--c3);}
    .App-footer a {text-decoration: none; color: #111111; color: var(--c8); display: block; padding: 5px 0; white-space: nowrap;}
    .App-footer .footerText {text-align: center; margin: 6px 0; color: #777; color: #BBBBBB; color: var(--c3); font-size: 12px; font-weight: 300;}
    .App-footer.alt a {display: inline-block;}
    .App-footer a:hover {color: #4CBF00;color: var(--brand);}
      .App-footer a i {margin-right: 5px;}
    .App-footer .uiMenu {float: right;}
    .App-footer .links {display: -webkit-flex;display: flex; margin: 10px 0;}
    .App-footer .footerSocial {text-align: right;}
      .App-footer .links a, .App-footer .links .btn {-webkit-flex: 1 1;flex: 1 1; text-align: center; margin: 0 2px; box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25); box-shadow: var(--small-shadow); font-weight: 300; border-radius: 5px; padding: 10px 0; height: 40px;}
      .App-footer .links a {background-color: #FFFFFF;background-color: var(--c1);}
      .App-footer .links a:hover {background-color: #F0F0F0;background-color: var(--c2); color: #111111; color: var(--c8);}
    .App-footer.alt .social-media {text-align: center; margin-bottom: 40px;}
      .App-footer.alt .social-media a {margin: 0 5px; box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25); box-shadow: var(--small-shadow); padding: 5px 0; width: 90px; font-size: 18px; border-radius: 5px; margin-bottom: 15px;}
      .App-footer.alt .social-media a:hover {-webkit-transform: scale(1.15);transform: scale(1.15);}
  @media (max-width: 990px) {
    .App-footer .links {display: block;}
    .App-footer .links a, .App-footer .links .btn {display: block; margin-bottom: 5px; width: 100%;}
    .App-footer.alt h6 {text-align: center;}
    .App-footer.alt .social-media {text-align: center;}
  }

/* TABLES */
  table {width: 100%;}
    th {padding: 0 20px 10px; font-weight: 400; color: #777777; color: var(--c5); white-space: nowrap;}
    th.sortable {cursor: pointer;}
      th .fa {padding-left: 10px; cursor: pointer;}
      th.active .fa {color: #4CBF00;color: var(--brand);}
    tbody tr {background-color: #F0F0F0;background-color: var(--c2); border: 4px solid #FFFFFF; border: 4px solid var(--c1); border-left: none; border-right: none;}
    tbody tr:first-child {border-top: none;} tbody tr:last-child {border-bottom: none;} tbody tr:hover {background-color: #BBBBBB;background-color: var(--c3);}
      td {padding: 4px 20px; white-space: nowrap;}
        td img {height: 20px; vertical-align: top; margin-right: 10px;}
        td:last-child button, td:last-child .btn-group {float: right;}
  .tableActions {margin-bottom: 10px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; overflow: auto; overflow-y: hidden;}
    .tableActions > .form-group, .tableActions > .btn-group, .tableActions > .form-control, .tableActions > .btn {margin-right: 10px!important; margin-bottom: 0!important; white-space: nowrap;}
    .tableActions .form-group:last-child, .tableActions .btn-group:last-child, .tableActions .form-control:last-child, .tableActions .btn:last-child {margin-right: 0;}
    .tableActions .select .form-control {display: inline-block; width: auto;}
    .tableActions h3 {margin-bottom: 0!important;}

/* FORMS */
  .form-group {margin: 0;}
  .form .form-group, form .form-group {margin: 10px 0;}
  .form button.btn, form button.btn {margin-top: 10px;}
  .form p, form p {color: #777777;color: var(--c5); margin: 10px 0 20px;}
  label {font-weight: 400; white-space: nowrap; margin-bottom: 0;}
  .form-control {color: #555555;color: var(--c6); border-color: #F0F0F0; border-color: var(--c2); border-radius: 0; box-shadow: none; padding: 0 20px; height: 30px; font-size: 14px; background-color: #FFFFFF; background-color: var(--c1);}
  .form-control[readonly] {background-color: #FFFFFF;background-color: var(--c1);}
  .has-success .form-control {border-color: #4CBF00;border-color: var(--brand); border-width: 2px;}
  .has-error .form-control {border-color: #FF0000;border-color: var(--danger); border-width: 2px;}
  .form-control.small {font-size: 12px; height: 20px; padding: 0 12px;}
  .checkbox {margin: 5px 0 5px 20px;}
    .checkbox label {padding: 0; margin: 0;}
  .input-group {border: 1px solid #BBBBBB;border: 1px solid var(--c3); background-color: #FFFFFF; background-color: var(--c1);}
  .input-group .form-control{border: none; height: 28px;}
  .input-group-addon {border-radius: 0; border: none; background: transparent; padding: 0 0 0 12px;}
  .select {position: relative; /*display: flex; align-items: center;*/}
    .select label {margin-right: 10px;}
    .select .form-control {-moz-appearance: none; -webkit-appearance: none; appearance: none; vertical-align: top;}
    .select.placeholder .form-control {color: #999999;color: var(--c4);}
    .select option {color: #333333;color: var(--c7);}
    .select option.disabled {color: #777777;color: var(--c5);}
    .select .fa {position: absolute; top: 5px; right: 8px; pointer-events: none; color: #555555; color: var(--c6);}
    .select .fa.small {top: 0.5px; right: 5px;}
    .uiSelect {position: relative;}
      .uiSelect input {cursor: default;}
    .select .options {position: fixed; z-index: 50; background-color: #FFFFFF; background-color: var(--c1); padding: 5px 0; overflow: auto; max-height: 300px; border: 1px solid #F0F0F0; border: 1px solid var(--c2);}
      .select .options > div {color: #111111;color: var(--c8); padding: 0 15px; white-space: nowrap;}
      .select .options > div:hover {background-color: #4CBF00;background-color: var(--brand);}

  .password-score .progress {height: 12px; border-radius: 0; border: 1px solid #BBBBBB; border: 1px solid var(--c3);; background: #F0F0F0;; background: var(--c2); margin: -1px 0 10px; box-shadow: none;}
    .password-score .progress.score-0 .progress-bar {background: #666666;}
    .password-score .progress.score-1 .progress-bar {background: #FF0000;}
    .password-score .progress.score-2 .progress-bar {background: #FFA000;}
    .password-score .progress.score-3 .progress-bar {background: #0FC100;}
    .password-score .progress.score-4 .progress-bar {background: #006CFF;}
  .password-score > span {}
    .password-score > span > span {padding-left: 15px;}
    .password-score span.score-1 {color: #FF0000;}
    .password-score span.score-2 {color: #FFA000;}
    .password-score span.score-3 {color: #0FC100;}
    .password-score span.score-4 {color: #3C96FF;}

/* BUTTONS */
  .btn {border-radius: 5px; font-family: 'Exo', sans-serif; font-family: var(--font2); font-weight: 600; height: 30px; padding: 0 15px; text-shadow: none; box-shadow: none;}
  .btn.small {font-size: 12px; height: 20px;}
  .btn.large {font-size: 18px; height: 40px;}
  .btn.wide {padding: 0 30px;}
  .btn.extraWide {max-width: 100%; width: 325px;}
  .btn.xxWide {max-width: 100%; width: 500px;}
  .btn.full-width {min-width: 100%;}
  .btn.shadowed {box-shadow: 0px 2px 2px 0px rgba(31,31,31,0.25);box-shadow: var(--small-shadow);}
  .btn.rounded {border-radius: 5px;}

  .btn img {height: 20px; margin-right: 10px; vertical-align: top;}

  /* Default / Gray */
  .btn-default {background-color: #555555;background-color: var(--c6); border-color: #555555; border-color: var(--c6); color: #FFFFFF; color: var(--c1); background-image: none!important;}
  .btn-default:hover, .btn-default:focus {background-color: #555555;background-color: var(--c6); border-color: #555555; border-color: var(--c6); color: #111111; color: var(--c8);}
  .btn-default.active, .btn-default.active:hover, .btn-default:active, .btn-default:active:hover {background-color: #555555;background-color: var(--c6); border-color: #555555; border-color: var(--c6); color: #4CBF00; color: var(--brand);}
  .btn-default[disabled], .btn-default[disabled]:hover {background-color: #F0F0F0;background-color: var(--c2); border-color: #F0F0F0; border-color: var(--c2); color: #555555; color: var(--c6); opacity: 1;}

  /* Primary / Brand */
  .btn-primary {background-color: #4CBF00;background-color: var(--brand); border-color: #4CBF00; border-color: var(--brand); color: #FFFFFF; color: var(--c1); background-image: none!important;}
  .btn-primary:hover, .btn-primary:focus {background-color: #4CBF00;background-color: var(--brand); border-color: #4CBF00; border-color: var(--brand); color: #111111; color: var(--c8);}
  .btn-primary.active, .btn-primary.active:hover, .btn-primary:active, .btn-primary:active:hover {background-color: #111111;background-color: var(--c8); border-color: #111111; border-color: var(--c8); color: #FFFFFF; color: var(--c1);}
  .btn-primary[disabled], .btn-primary[disabled]:hover {background-color: #F0F0F0;background-color: var(--c2); border-color: #F0F0F0; border-color: var(--c2); color: #555555; color: var(--c6); opacity: 1;}

  /* blue / Brand */
  .btn-blue {background-color:#0749AE; border-color: #0749AE; color: #FFFFFF; color: var(--c1); background-image: none!important;}
  .btn-blue:hover, .btn-blue:focus {background-color: #0749AE; border-color: #0749AE; color: #111111; color: var(--c8);}
  .btn-blue.active, .btn-blue.active:hover, .btn-blue:active, .btn-blue:active:hover {background-color: #111111;background-color: var(--c8); border-color: #111111; border-color: var(--c8); color: #FFFFFF; color: var(--c1);}
  .btn-blue[disabled], .btn-blue[disabled]:hover {background-color: #F0F0F0;background-color: var(--c2); border-color: #F0F0F0; border-color: var(--c2); color: #555555; color: var(--c6); opacity: 1;}

  /* orange / Brand */
  .btn-orange {background-color:#FF9200; border-color: #FF9200; color: #FFFFFF; color: var(--c1); background-image: none!important;}
  .btn-orange:hover, .btn-orange:focus {background-color: #FF9200; border-color: #FF9200; color: #111111; color: var(--c8);}
  .btn-orange.active, .btn-orange.active:hover, .btn-orange:active, .btn-orange:active:hover {background-color: #111111;background-color: var(--c8); border-color: #111111; border-color: var(--c8); color: #FFFFFF; color: var(--c1);}
  .btn-orange[disabled], .btn-orange[disabled]:hover {background-color: #F0F0F0;background-color: var(--c2); border-color: #F0F0F0; border-color: var(--c2); color: #555555; color: var(--c6); opacity: 1;}

  /* yellow / Brand */
  .btn-yellow {background-color:#FFCE00; border-color: #FFCE00; color: #FFFFFF; color: var(--c1); background-image: none!important;}
  .btn-yellow:hover, .btn-yellow:focus {background-color: #FFCE00; border-color: #FFCE00; color: #111111; color: var(--c8);}
  .btn-yellow.active, .btn-yellow.active:hover, .btn-yellow:active, .btn-yellow:active:hover {background-color: #111111;background-color: var(--c8); border-color: #111111; border-color: var(--c8); color: #FFFFFF; color: var(--c1);}
  .btn-yellow[disabled], .btn-yellow[disabled]:hover {background-color: #F0F0F0;background-color: var(--c2); border-color: #F0F0F0; border-color: var(--c2); color: #555555; color: var(--c6); opacity: 1;}

  /* Info / Outline */
  .btn-info {background-color: #FFFFFF;background-color: var(--c1); border-color: #BBBBBB; border-color: var(--c3); color: #555555; color: var(--c6); background-image: none!important;}
  .btn-info:hover, .btn-info:focus {background-color: #FFFFFF;background-color: var(--c1); border-color: #111111; border-color: var(--c8); color: #111111; color: var(--c8);}
  .btn-info.active, .btn-info.active:hover, .btn-info:active, .btn-info:active:hover {background-color: #FFFFFF;background-color: var(--c1); border-color: #4CBF00; border-color: var(--brand); color: #4CBF00; color: var(--brand);}
  .btn-info[disabled], .btn-info[disabled]:hover {background-color: #FFFFFF;background-color: var(--c1); border-color: #F0F0F0; border-color: var(--c2); color: #F0F0F0; color: var(--c2); opacity: 1;}

  /* Link / White */
  .btn-link {background-color: #FFFFFF;background-color: var(--c1); border-color: #FFFFFF; border-color: var(--c1); color: #555555; color: var(--c6); background-image: none!important;}
  .btn-link:hover, .btn-link:focus {background-color: #FFFFFF;background-color: var(--c1); border-color: #FFFFFF; border-color: var(--c1); color: #111111; color: var(--c8); text-decoration: none;}
  .btn-link.active, .btn-link.active:hover, .btn-link:active, .btn-link:active:hover {background-color: #FFFFFF;background-color: var(--c1); border-color: #FFFFFF; border-color: var(--c1); color: #4CBF00; color: var(--brand);}
  .btn-link[disabled], .btn-link[disabled]:hover {background-color: #FFFFFF;background-color: var(--c1); border-color: #FFFFFF; border-color: var(--c1); color: #F0F0F0; color: var(--c2); opacity: 1;}

  .btn-group .btn {margin-right: 10px; float: none;}
  .btn-group .btn:last-child {margin-right: 0;}

/* DYNAMIC CONTAINER */
  .uiContainer {background-color: #FFFFFF;background-color: var(--c1);}

    .uiRow {cursor: row-resize; padding-bottom: 6px;}
    .uiRow:first-child {padding-top: 6px;}
    .uiRow.empty {display: none;}
    .uiRow.draggingTo {border-top: 3px solid #4CBF00;border-top: 3px solid var(--brand); padding-top: 2px;}
    .uiRow.draggingTo.dragging-bottom {border-top: none; border-bottom: 3px solid #4CBF00; border-bottom: 3px solid var(--brand);}
    .uiRow.resizing {border-bottom: 3px solid #4CBF00;border-bottom: 3px solid var(--brand); padding-bottom: 2px;}

      .uiColumn {display: inline-block; height: 100%; padding-right: 6px; cursor: col-resize; vertical-align: top;}
      .uiColumn:first-child {padding-left: 6px;}
      .uiColumn.empty {display: none;}
      .uiColumn.draggingTo {border-left: 3px solid #4CBF00;border-left: 3px solid var(--brand); padding-left: 2px;}
      .uiColumn.draggingTo.dragging-right {border-left: none; border-right: 3px solid #4CBF00; border-right: 3px solid var(--brand);}
      .uiColumn.resizing {border-right: 3px solid #4CBF00;border-right: 3px solid var(--brand); padding-right: 2px;}

        .uiContent {background: #F0F0F0;background: var(--c2); height: 100%; cursor: auto; cursor: initial; position: relative; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; color: #777777; color: var(--c5);}
        .uiRow.resizing .uiContent {cursor: row-resize;}
        .uiColumn.resizing .uiContent {cursor: col-resize;}

          .uiHead {background: #BBBBBB;background: var(--c3); font-family: 'Exo', sans-serif; font-family: var(--font2); font-weight: 600; text-transform: uppercase;}

            .uiTabs {background: #F0F0F0;background: var(--c2);}
            .uiTabs.only-tab {background: transparent;}

              .uiTab {display: inline-block; padding: 3px 10px; cursor: pointer;}
              .uiTab:hover {background: #BBBBBB;background: var(--c3);}
              .uiTab.active {background: #BBBBBB;background: var(--c3); color: #111111; color: var(--c8); cursor: default;}
              .only-tab .uiTab.active {background: transparent;}
              .uiTab.dragging {background: #4CBF00!important;background: var(--brand)!important;}
              .uiTab.draggingTo {border-left: 2px solid #4CBF00;border-left: 2px solid var(--brand);}
              .uiHead.draggingTo .uiTab:last-child {border-right: 2px solid #4CBF00;border-right: 2px solid var(--brand);}
              
                .uiTab .actions {margin-left: 10px; display: inline;}
                  .uiTab .actions a {padding: 0 5px; color: #111111; color: var(--c8);}
                  .uiTab .actions button {margin: 0 5px; vertical-align: top;}

          .uiBody {/* overflow: hidden; height: max-content; */-webkit-flex: 1 1;flex: 1 1; min-height: 0; min-width: 0;}
            .uiBodyHead {padding: 5px; display: -webkit-flex; display: flex; background: #BBBBBB; background: var(--c3); -webkit-align-items: center; align-items: center;}
            .headIsHidden .uiBodyHead {display: none!important;}

            .uiBodyHead > .form-group, .uiBodyHead > .btn-group, .uiBodyHead > .form-control, .uiBodyHead > .btn, .uiBodyHead > .input-group, .uiBodyHead > .DateRangePicker {margin-right: 5px;}
            .uiBodyHead .form-group:last-child, .uiBodyHead .btn-group:last-child, .uiBodyHead .form-control:last-child, .uiBodyHead .btn:last-child, .uiBodyHead .input-group:last-child {margin-right: 0;}
            .uiBodyHead .select .form-control {display: inline-block; width: auto;}
            .uiBodyHead .input-group {border-color: #FFFFFF;border-color: var(--c1);}
            .uiBodyHead .separator {margin: 0 10px;}

            .mobile .uiBodyHead {overflow-x: auto;}
              .mobile .uiBodyHead button.btn {font-size: 12px; font-weight: 400; text-transform: uppercase; font-family: 'Roboto', sans-serif; font-family: var(--font); padding: 0px 10px; height: 25px; margin-right: 5px;}
              .mobile .uiBodyHead .input-group {margin-right: 5px;}
              .mobile .uiBodyHead .input-group-addon {padding: 5px 0 5px 12px;}
              .mobile .uiBodyHead .form-control {max-height: 24px; min-width: 100px;}

            /* .uiBodyTabs {background: var(--c3);}
              .uiBodyTabs > div {cursor: pointer; display: inline-block; padding: 5px 10px; color: var(--c5);}
              .uiBodyTabs > div.active {background: var(--c2); cursor: default; color: var(--c8);} */

            .uiBodyContent {padding: 10px;}

/* FEATURED CURRENCY */
  .featuredCurrency {background-color :#FFFFFF;background-color :var(--c1); padding: 10px;}
      .featuredCurrency .row:first-child {margin-bottom: 10px;}
      .featuredCurrency .row:first-child > div {display: -webkit-flex;display: flex;}
    .featuredCurrency .select {margin: 0; -webkit-flex-grow: 1; flex-grow: 1;}
    .featuredCurrency .select:first-child {margin-right: 10px;}
    .featuredCurrency .name {display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center;}
    .featuredCurrency h6 {padding: 2px 0;}
    .featuredCurrency h3 {display: inline-block; margin-left: 10px; margin-bottom: 0;}

/* FEATURED Pair */
  .featuredPair {background-color :#FFFFFF;background-color :var(--c1); padding: 10px; margin: 10px 0;}
    .featuredPair > .row > div:first-child {padding-right: 0;}
    .featuredPair h6 {margin: 0 0 3.5px 0; font-weight: 400; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .featuredPair h5 {margin: 0 0 5px 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .featuredPair h3 {margin: 0 0 2.5px 0; font-size: 22px;}

/* LOGIN */
  .auth {background: transparent; width: 460px; max-width: 85vw;}
    .auth h3 {text-align: center; font-size: 35px; color: #FFFFFF; color: var(--c1); margin-bottom: 35px;}
  .auth > .head {height: 40px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; background: #333333; background: var(--c7); padding: 0 20px;}
    .auth > .head img {height: 28px; margin-left: 20px;}
    .auth > .head .cancel {cursor: pointer; font-size: 18px; margin-left: auto; color: #FFFFFF; color: var(--c1);}
  .auth .foot {background: #F0F0F0;background: var(--c2); padding: 0 40px; height: 40px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center;}
    .auth .foot button {margin-left: auto;}
  .auth > .segment {padding: 10px 40px; max-height: 70vh; overflow-y: auto;}
  .auth .tos {position: fixed; top: 50px; height: calc(100vh - 150px); left: 5%; right: 5%; background-color: #FFFFFF; background-color: var(--c1);}
    .auth .tos .terms {height: calc(100% - 105px); overflow: auto; border: 1px solid #F0F0F0; border: 1px solid var(--c2); padding: 10px; -webkit-overflow-scrolling: touch;}
    .auth .tos h4 {color: #111111;color: var(--c8); text-align: center; margin-top: 15px;}

/* FUNDS */
  .Funds {overflow: auto; height: 100%; overflow-x: hidden;}
    .Funds .addNewCurrency {margin: 10px 0;}

/* EXTERNAL WALLETS */
  .ExternalWallets {overflow: auto; height: 100%; overflow-x: hidden;}
    .ExternalWallets .addNewAccount {margin: 10px 0;}
    .ExternalWallets .tag {border: none; padding: 0;}

/* WITHDRAWAL AND DEPOSIT */
  .Withdrawal.uiModal .body , .Deposit.uiModal .body {padding: 15px 20px;}
    .Withdrawal .body > .row , .Deposit .body > .row {margin-bottom: 10px;}
    .Withdrawal .body > .segment , .Deposit .body > .segment {min-height: 100px;}
  .Withdrawal .address.form-control {width: 87%; display: inline-block;}
  .Withdrawal .address.fa {width: 10%; height: 29px; vertical-align: top; background: #999999; background: var(--c4); margin-left: 3%; color: #111111; color: var(--c8); padding: 7px 0px; cursor: pointer; text-align: center; font-size: 16px;}
  .Withdrawal .select {margin: 0;}
  .Withdrawal .form-control {color: #777777 !important;color: var(--c5) !important;}
  .Withdrawal .currencyFunds {background: #BBBBBB;background: var(--c3); margin: 0; padding: 10px 0px;}
    .Withdrawal .currencyFunds > div {white-space: nowrap;}
  .Deposit canvas {background: white; padding: 5px;}
  .Deposit label {overflow: hidden; white-space: normal;}
  .Deposit th {padding-bottom: 10px !important;}
  .Deposit tr {border-color: #F0F0F0;border-color: var(--c2);}
  .Deposit td {background: #BBBBBB;background: var(--c3);}
  .Deposit .deposit td {background: #333333!important;background: var(--c7)!important;}

/* CHART */
  .Chart {height: 100%; width: 100%; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; overflow: hidden;}
  .Chart .segment {display: -webkit-flex;display: flex; -webkit-flex-direction: column; flex-direction: column;}
    .chartContainer {width: 100%; height: 100%;}
    .stx_grid, .stx_grid_border {color: #BBBBBB;color: var(--c3);}
    .stx_yaxis, .stx_xaxis {color: #111111;color: var(--c8);}
    .stx_current_hr_up {background: #1CB787;background: var(--bull);}
    .stx_current_hr_down {background: #FF41A1;background: var(--bear);}
    .stx-panel-title {color: #111111;color: var(--c8); background: #BBBBBB; background: var(--c3);}
    .stx-btn-panel {color: #111111;color: var(--c8); background: #BBBBBB; background: var(--c3);}
    .stx-panel-control.stx-show .stx-btn-panel.stx-show { display: inline-block; }
    .stx-panel-title.chart-title {display: none;}
    .stx-chart-panel {padding: 0 10px;}
    #chartSize {display: none;}

/* DEPTH CHART */
  .DepthChart {position: relative; width: 100%; height: 100%; overflow: hidden;}
    .DepthChart .notEnoughData {position: absolute; width: 100%; height: 100%; text-align: center; -webkit-transform: translate(0,45%); transform: translate(0,45%); font-size: 20px; top: 0; left: 0;}
    .DepthChart canvas {position: absolute; top: 0; left: 0;}

/* ORDER BOOK */
  .orderBook {height: 100%; display: -webkit-flex; display: flex; -webkit-flex-flow: column; flex-flow: column;}
    .orderBook .segment {-webkit-flex: 1 1 auto;flex: 1 1 auto; overflow: hidden;}
    .orderBook th, .orderBook td {text-align: right;}
    .orderBook th:first-child, .orderBook td:first-child {text-align: left;}
    .orderBook tr.divider {background: #BBBBBB;background: var(--c3);}
    .orderBook tr.divider td {padding: 2.5px 10px;}
    .orderBook td {position: relative;}
    .orderBook td .marker {margin-right: 5px; color: #FFCE00; color: var(--alert);}
    .orderBook td .bookRatio {position: absolute; left: 0; top: 0; height: 100%; opacity: 0.25;}
    .orderBook td.bear .bookRatio {background: #FF41A1;background: var(--bear);}
    .orderBook td.bull .bookRatio {background: #1CB787;background: var(--bull);}
    .orderBook td.midMarketPrice {text-align: left; cursor: row-resize;}
      .orderBook td.midMarketPrice > span {float: right; font-weight: 600;}
      .orderBook td.midMarketPrice .value {margin-right: 5px; font-size: 16px; height: 16px; line-height: 0;}
        .orderBook td.midMarketPrice .value .fa {margin-left: 5px;}

/* RECENT TRADES */
  .tradeHistory {overflow: hidden;}

/* ORDER FORM */
  .orderForm {display: -webkit-flex;display: flex; height: 100%; overflow: auto; padding: 10px 0; overflow-x: hidden;}
    .orderForm .funds {margin: 0; border: 2px solid #777777; border: 2px solid var(--c5);}
      .orderForm .funds > div:first-child {background-color: #777777;background-color: var(--c5); color: #FFFFFF; color: var(--c1); padding: 0; text-align: center;}
        .orderForm .funds label, .orderForm .funds .input-group-addon {padding: 0;}
      .orderForm .funds input.form-control {height: 20px;}
    .orderForm .action-switch {height: 25px; margin-bottom: 7.5px;}
      .orderForm .action-switch .btn.buy, .orderForm .action-switch .btn.sell {background-color: #999999!important;background-color: var(--c4)!important;}
      .orderForm .action-switch .btn.buy.active {background-color: #1CB787!important;background-color: var(--bull)!important;}
      .orderForm .action-switch .btn.sell.active {background-color: #FF41A1!important;background-color: var(--bear)!important;}
    .orderForm .fees-switch {height: 30px; border: 2px solid #BBBBBB; border: 2px solid var(--c3);}
      .orderForm .fees-switch .btn {padding: 0; background: transparent; color: #BBBBBB; color: var(--c3);}
      .orderForm .fees-switch .btn.active {background: #111111;background: var(--c8); color: #FFFFFF; color: var(--c1);}
    .orderForm .fields {margin: 0 auto; min-width: 250px; max-width: 800px;}
      .orderForm .fields .action {margin: 10px 0;}
    .orderForm .form-group.select {display: block;}
      .orderForm .select .form-control {width: 100%;}
      .orderForm .select .fa {position: absolute; right: 25px; top: 5px;}
    .orderForm .form-control {padding: 0 10px; border: 0;}
    .orderForm .form-group {margin-bottom: 3px;}
    .orderForm .input-group {border: none; background: #FFFFFF; background: var(--c1);}
    .orderForm .input-group.readOnly {background: none;}
    .orderForm .input-group-addon {padding-right: 12px; color: #555555; color: var(--c6);}
    .orderForm .input-group.readOnly .form-control {background: transparent;}
    .orderForm .percentage .rc-slider {margin-top: 5px; width: 110%;}
    .orderForm .percentage .form-control {text-align: center;}
    .orderForm .btn-group {width: 100%;}
      .orderForm .btn-group .btn {margin: 0; width: 50%; height: 100%;}
    .orderForm label {padding: 4px 0px;}
      .orderForm label .fa {margin-left: 5px;}
    .orderForm button {margin-top: 10px; border: 0;}
    .orderForm button.buy {background-color: #1CB787!important;background-color: var(--bull)!important; color: #FFFFFF!important; color: var(--c1)!important;}
    .orderForm button.sell {background-color: #FF41A1!important;background-color: var(--bear)!important; color: #FFFFFF!important; color: var(--c1)!important;}
    .orderForm button.buy[disabled] {opacity: 0.5;}
    .orderForm button.sell[disabled] {opacity: 0.5;}
  .MakeOrder {height: 100%; padding: 5px }
    .MakeOrder button { height: 100%; margin: 0; }
    .MakeOrder button.order {background: #4CBF00;background: var(--brand); color: #111111; color: var(--c8); border-color: #4CBF00; border-color: var(--brand);}

/* DRAGGY THING */
  .rc-slider {}
    .rc-slider-track {background-color: #777777;background-color: var(--c5);}
    .rc-slider-handle {border: solid 2px #777777;border: solid 2px var(--c5);}
    .rc-slider-handle:hover, .rc-slider-handle:active {border-color: #4CBF00;border-color: var(--brand);}

/* MY ORDERS */
  .MyOrders {height: 100%; overflow: auto; overflow-y: hidden;}
    .MyOrders > span {display: inline-block; white-space: nowrap; min-width: 100%; height: 100%;}
    .MyOrders .uiBodyHead {white-space: normal; display: -webkit-inline-flex; display: inline-flex; min-width: 100%;}
    .MyOrders .segment {white-space: normal; display: inline-block; height: calc(100% - 30px); min-width: 100%;}
    .MyOrders .uiModal .separator {border-bottom: 1px solid #999999;border-bottom: 1px solid var(--c4); margin: 15px 0;}

/* ARTICLES */
  .Articles {height: 100%; display: -webkit-flex; display: flex; -webkit-flex-flow: column; flex-flow: column; overflow: hidden;}
    .Articles .foot {padding: 30px 30px 50px; background-color: #4CBF00; background-color: var(--brand); box-shadow:0px 0px 0px 1px #ffffff inset; border: 5px solid #4CBF00; border: 5px solid var(--brand);}
      .Articles .foot h1 {margin-top: 0; text-align: center; margin-bottom: 5px; color: #FFFFFF; color: var(--c1);}
      .Articles .foot h3 {margin-bottom: 10px; display: block; margin-top: 10px; color: #555555; color: var(--c6); font-family: 'Exo', sans-serif; font-family: var(--font2);}
    .Articles > .row {padding: 15px; -webkit-flex: 1 1 auto; flex: 1 1 auto; overflow: auto;}
      .Articles > .row > div {margin-bottom: 20px;}
        .Articles > .row > div > div {padding: 10px; box-shadow: 2px 2px 5px 0px rgba(31,31,31,0.25); box-shadow: var(--shadow); border-radius: 5px; min-height: 200px; cursor: pointer;}
        .Articles > .row > div > div:hover {background-color: #F0F0F0;background-color: var(--c2);}
    .mobile .Articles > .row {padding: 5px;}

    .Articles .row.list {display: -webkit-flex;display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;}
      .Articles .row.list .article {margin-bottom: 25px;}
      .Articles .row.list .image {background-color: #777777;background-color: var(--c5); width: 100%; height: 125px; position: relative; margin-bottom: 5px; cursor: pointer; overflow: hidden; background-size: contain; background-position: center center; background-repeat: no-repeat;}
      .Articles .row.list h5 {margin: 6px 0;}
      .Articles .row.list h6 {overflow: hidden; color: #999999; color: var(--c4); margin: 8px 0 0;}
      .Articles .row.list p {color: #BBBBBB;color: var(--c3); font-size: 12px; word-wrap: break-word; text-overflow: ellipsis; max-height: 75px; overflow: hidden; line-height: 15px;}
   
    .Articles .row.article {}
      .Articles .row.article h5 {color: #777777;color: var(--c5);}

    .Articles .row.article .articleBody {}
      .Articles .row.article .articleBody h4 {margin: 35px 0;}
      .Articles .row.article .articleBody h5 {color: #111111;color: var(--c8); margin: 25px 0;}
      .Articles .row.article .articleBody p {margin: 12px 0;}
      .Articles .row.article .articleBody img {width: auto; margin: 15px 0; max-width: 75%; border: 1px solid #111111; border: 1px solid var(--c8);}

    .Articles .listItem {margin-bottom: 10px;}
      .Articles .listItem p {margin: 10px 0;}
      .Articles .listItem h6 {font-size: 14px; margin: 0!important;}
      .Articles .listItem h4 {display: inline-block; margin: 0 0 0 5px; color: #999999; color: var(--c4); font-size: 13px;}
      .Articles .listItem h5 {display: inline-block;}

/* PAIRS */
  .pair-selection .tableActions .fav .fa {margin-left: 10px;}
  .pair-selection .pairs tbody .fa {color: #FFFFFF;color: var(--c1); text-shadow: -1px 0 #555555, 0 1px #555555, 1px 0 #555555, 0 -1px #555555; text-shadow: -1px 0 var(--c6), 0 1px var(--c6), 1px 0 var(--c6), 0 -1px var(--c6); cursor: pointer;}
  .pair-selection .pairs tbody .fa.isFav, .pairs tbody .fa:hover {color: #4CBF00;color: var(--brand);}

/* CALCULATOR */
  .Calculator {overflow: auto; padding: 15px 10px; height: 100%; overflow-x: hidden;}
    .Calculator > .row {margin-bottom: 10px;}
      .Calculator > .row > div {display: -webkit-flex;display: flex; max-width: 350px;}
        .Calculator > .row > div > .form-control {width: auto; margin-left: 10px; -webkit-flex-grow: 1; flex-grow: 1;}
    .Calculator .select{min-width: 100px;}
      .Calculator .select select {padding-left: 30px !important; width: 100px!important;}
      .Calculator .select img {position: absolute; left: 5px; height: 65%; top: 17.5%;}
    .Calculator .usdValueLabel {background: #111111;background: var(--c8); display: block; padding: 5px 15px; min-width: 100px; white-space: nowrap;}
    
/* MARKET ANALYSIS */
  .MarketAnalysis {height: 100%; display: -webkit-flex; display: flex; -webkit-flex-flow: column; flex-flow: column; overflow: hidden; font-family: 'Roboto', sans-serif; font-family: var(--font); white-space: nowrap;}
    .MarketAnalysis .uiBodyHead .select .form-control {padding: 0 20px !important;}
    .MarketAnalysis .actions {padding: 5px;}
      .MarketAnalysis .select {margin: 0; margin-right: 15px;}
      .MarketAnalysis label {margin-right: 10px;}
    .MarketAnalysis > .row {padding: 5px 20px; -webkit-flex: 1 1; flex: 1 1; overflow: auto;}
    .mobile .MarketAnalysis > .row {padding: 0 5px;}
    .MarketAnalysis .item {background: #F0F0F0;background: var(--c2); margin: 5px 0; padding: 5px 0;}
      .MarketAnalysis .item .btn {float: right;}
      .MarketAnalysis .item .name {font-weight: 300; color: #777777; color: var(--c5); padding: 3px 15px;}
      .MarketAnalysis .item .btn {margin: 3px 0;}
      .MarketAnalysis .item .bar-container {padding: 3px 15px;}
        .MarketAnalysis .item .bar {width: 75%; height: 6px; background: #FF41A1; background: var(--bear); display: inline-block; vertical-align: middle;}
          .MarketAnalysis .item .bar.coins {background: #777777;background: var(--c5);}
            .MarketAnalysis .item .bar > div {background: #1CB787;background: var(--bull); height: 100%;}
            .MarketAnalysis .item .bar.blue > div {float: right;}
            .MarketAnalysis .item .bar.bear > div {background: #FF41A1;background: var(--bear);}
            .MarketAnalysis .item .value {color: #999999;color: var(--c4); font-size: 12.5px; padding: 0 2%;}

/* MY PAGE */
  .MyPage {min-height: calc(100vh - 40px);}

/* COINVENIENCE */
  .Coinvenience {}
    .Coinvenience h4 {margin-bottom: 5px;}
    .Coinvenience input::-webkit-outer-spin-button {
      -webkit-appearance: outer-spin-button;
      margin: 0;
      margin: initial;
    }
    .Coinvenience input::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button;
      margin: 0;
      margin: initial;
    }
    .Coinvenience input[type=number] {
      -moz-appearance: initial;
      z-index: 1;
      position: relative;
    }
    .Coinvenience .has-error input.form-control {border-color: #FFCE00;border-color: var(--alert);}
    .Coinvenience .inputError {background-color: #FFCE00;background-color: var(--alert); padding: 2px 15px; font-weight: 600; color: #111111; color: var(--c8); font-size: 12px; -webkit-transform: translateY(-100%); transform: translateY(-100%); transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s; white-space: nowrap;}
      .Coinvenience .inputError.show {-webkit-transform: none;transform: none;}
      .Coinvenience .spendGet .row {margin-left: 0; margin-right: 0; text-align:center}
    .Coinvenience .spendGet .row > div:first-child {padding-right: 0;}
    .Coinvenience .spendGet .row > div:last-child {padding-left: 0;}
    .Coinvenience .top {position:relative; margin: -20px -21px 0; background-color: #111111; background-color: var(--c8); color: #FFFFFF; color: var(--c1); font-family: 'Exo', sans-serif; font-family: var(--font2); font-style: italic; font-weight: 100; padding: 4px 20px; font-size: 18px; height: 32px;}
    .Coinvenience .top img {margin-top: -15px; float: left; height: 56px; margin-right: 5px;}
    .Coinvenience .top span {display: block;}
    .Coinvenience .buySell {width: 100%;}
      .Coinvenience .top .buySell {font-style: normal;font-style: initial; position: absolute; top: 0; right: 20px; height: 100%; text-align: right;}
      .Coinvenience .buySell .btn {margin: 0; width: 50%; border: 0; background-color: #BBBBBB; background-color: var(--c3); font-weight: 300;}
      .Coinvenience .top .buySell .btn {width: auto; height: 100%; padding: 0 40px; transition: -webkit-transform 0.25s; transition: transform 0.25s; transition: transform 0.25s, -webkit-transform 0.25s;}
      .Coinvenience .top .buySell .btn.active {-webkit-transform: scale(1.1);transform: scale(1.1);}
      .Coinvenience .btn.buy {border-top-right-radius: none; border-bottom-right-radius: none; -webkit-transform-origin: 100% 50%; transform-origin: 100% 50%;}
      .Coinvenience .btn.sell {border-top-left-radius: none; border-bottom-left-radius: none; -webkit-transform-origin: 0% 50%; transform-origin: 0% 50%;}
      .Coinvenience .btn.buy.active {background-color: #1CB787;background-color: var(--bull); color: #FFFFFF; color: var(--c1);}
      .Coinvenience .btn.sell.active {background-color: #FF41A1;background-color: var(--bear); color: #FFFFFF; color: var(--c1);}
    .Coinvenience .segment {min-height: 180px;}
      .Coinvenience .segment .segment {padding: 10px 0; border-bottom: 2px solid #333333; border-bottom: 2px solid var(--c7);}
      .Coinvenience .segment .segment:last-child {border: none;}
      .Coinvenience .uiModal .segment {margin: 0; padding: 0;}
    .Coinvenience .rate {font-family: 'Exo', sans-serif;font-family: var(--font2); font-weight: 300; color: #333333; color: var(--c7); margin: 12px 0;}
      .Coinvenience .rate img {width: 18px; vertical-align: top; margin-right: 5px;}
      .Coinvenience .rate .px {position: relative;}
        .Coinvenience .rate .px .slashed {position: absolute; left: 0; top: -15px; text-decoration: line-through; color: #FF0000; color: var(--danger);}
    .Coinvenience .swap {background-color: #4CBF00;background-color: var(--brand); padding: 2px 5px; margin-right: 10px;}
      .Coinvenience .swap i {-webkit-transform: rotate(90deg);transform: rotate(90deg); transition: -webkit-transform .5s ease; transition: transform .5s ease; transition: transform .5s ease, -webkit-transform .5s ease;}
      .Coinvenience .swapped i {-webkit-transform: rotate(270deg);transform: rotate(270deg);}
    .Coinvenience .input-icon {position: absolute; height: 20px; top: 5px; left: 20px;}
    .Coinvenience .inputs {position: relative;}
      /* .Coinvenience .inputs .row {margin-bottom: 15px;} */
      .Coinvenience .select .form-control, .Coinvenience .base-symbol {background-color: #F0F0F0;background-color: var(--c2);}
      .Coinvenience .base-symbol {padding: 4px 15px; border: 1px solid #F0F0F0; border: 1px solid var(--c2);}
      .Coinvenience .inputs h3 {margin: 0px; text-align: center;}
      .Coinvenience .inputs .middle {padding: 150px 0;}
      .Coinvenience .inputs .spendGet {position: absolute; left: 0; right: 0; transition: top .5s ease;}
        .Coinvenience .inputs .spendGet p {margin-top: 5px;}

      .Coinvenience .inputs .spend.swapped, .Coinvenience .inputs .get {top: 190px;}
      .Coinvenience.fullLayout .inputs .spend.swapped, .Coinvenience.fullLayout .inputs .get {top: 190px;}

      .Coinvenience .inputs .spend {background-color: #D8EAFF;}
      .Coinvenience .inputs .spendMobile {background-color: #D8EAFF; padding: 5px;}
      .Coinvenience .inputs .get {background-color: #FFF6CD;}
      .Coinvenience .inputs .getMobile {background-color: #FFF6CD; padding: 5px;}
      .Coinvenience .inputs .get.swapped, .Coinvenience .inputs .spend {top: 0px;}
    .Coinvenience .expiresIn {font-size: 12px; color: #999999; color: var(--c4);}
    .Coinvenience .progress {margin: 0; box-shadow: none; border-radius: 0;}
      .Coinvenience .progress-bar {transition: width linear 1s;}
      .Coinvenience .progress[variant="success"] .progress-bar {background-color: #4CBF00;background-color: var(--brand);}
      .Coinvenience .progress[variant="warning"] .progress-bar {background-color: #FFCE00;background-color: var(--alert);}
      .Coinvenience .progress[variant="danger"] .progress-bar {background-color: #FF0000;background-color: var(--danger);}
    .Coinvenience .fiveSecondNotice {text-align: center; margin-top: 10px; color: #BBBBBB; color: var(--c3); font-weight: 300;}
    .Coinvenience .orderConfirmation .body {padding: 20px 80px;}
      .Coinvenience .orderConfirmation .body h4 {margin-bottom: 10px;}
      .Coinvenience .orderConfirmation .body .btn-group {margin-top: 15px; width: 100%;}
        .Coinvenience .orderConfirmation .body .btn-group .btn {margin-bottom: 5px;}
  .Coinvenience .AvailableBalance p {margin: 0;}
  .Coinvenience .AvailableBalance .barContainer {margin: 0 0 5px;}
  .Coinvenience .currencyBtn { text-align: center;}
  .Coinvenience .paddedMovingSection { padding: 5px;}
  @media (max-width: 990px) {
    .Coinvenience .inputs .middle {padding: 50px 0 50px;}
    .Coinvenience .inputs .spend.swapped, .Coinvenience .inputs .get {top: 105px;}
    .Coinvenience .inputs .spendGet > div, .Coinvenience .inputs .middle > div {margin-bottom: 10px;}
    .Coinvenience .inputs h3 {margin-bottom: 10px; }
    .Coinvenience .expiresIn {display: none;}
    .Coinvenience .progress {margin: 8px 0;}
  }

/* PROGRAM FORM */
  .ProgramForm {color: #999999;color: var(--c4); font-weight: 300;}
  .ProgramForm .space {width: 10px; display: inline-block;}
  .ProgramForm .formInputs h4 {margin-top: 5px!important; margin-bottom: 5px!important;}
  .ProgramForm .form-control {background-color: #F0F0F0;background-color: var(--c2); font-weight: 400;}
  .ProgramForm .auto .form-control {width: auto; max-width: 75px; display: inline-block;}
  .ProgramForm .auto .select {display: inline-block; vertical-align: middle;}
  .ProgramForm .auto .select .form-control {width: 100%; margin: 0; max-width: 150px;}
  .ProgramForm .dayOfWeek {display: inline-block; padding: 2px 10px; border: 1px solid #BBBBBB; border: 1px solid var(--c3); margin-right: 10px; cursor: pointer;}
  .ProgramForm .dayOfWeek.active {background-color: #4CBF00;background-color: var(--brand); color: #111111; color: var(--c8); border-color: #111111; border-color: var(--c8);}
  .ProgramForm span {padding: 5px 0; display: inline-block;}
  .ProgramForm .Deposit .form-control {background-color: #FFFFFF;background-color: var(--c1);}
  .ProgramForm .imgUsd {position: absolute; left: 20px; height: 20px; top: 5px;}
  .ProgramForm #totalAmount, .ProgramForm #purchaseAmount {padding-left: 35px;}
  .ProgramForm .SingleDatePickerInput, .ProgramForm #startDate {background-color: #F0F0F0;background-color: var(--c2); border: 0; font-weight: 300;}
  .ProgramForm .total-amount {margin-left: 35px;}
  .ProgramForm .has-error .total-amount {color: #FF0000!important;color: var(--danger)!important;}

/* PROGRAM LIST */
  .Programs {}
  .Programs th, .Programs td {padding-left: 10px; padding-right: 10px;}
  .Programs table .status, .Programs table .edit, .Programs table .delete {text-align: center;}
  .Programs table .status i, .Programs table .edit i, .Programs table .delete i {cursor: pointer;}
  .Programs .name i {margin-right: 10px;}
  .Programs .expanded .name i {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  .Programs tr.details {background-color: #FFFFFF;background-color: var(--c1);}
  .Programs tr.details td:first-child {border-right: 1px solid #BBBBBB;border-right: 1px solid var(--c3);}
  .Programs .progress {box-shadow: none; border: 1px solid #BBBBBB; border: 1px solid var(--c3); border-radius: 0; margin-bottom: 0;}
  .Programs .progress-bar {background-color: #4CBF00;background-color: var(--brand);}

/* PROGRAM PURCHASES */
  .ProgramPurchases {}
  .ProgramPurchases h4 {display: inline-block;}
  .ProgramPurchases .actions {display: inline-block; float: right;}
  .ProgramPurchases .checkbox, .ProgramPurchases .actions span, .ProgramPurchases .pagination {margin-left: 20px; display: inline-block;}
  .ProgramPurchases th, .ProgramPurchases td {padding-left: 10px; padding-right: 10px;}
  .ProgramPurchases td .clickable:hover {color: #333333;color: var(--c7); font-weight: 400;}
  .ProgramPurchases .name i {margin-right: 10px;}
  .ProgramPurchases .expanded .name i {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  .ProgramPurchases .status {}
  .ProgramPurchases .status.ACTIVE {color: #4CBF00;color: var(--brand);}
  .ProgramPurchases .status.PAUSED {color: #FFCE00;color: var(--alert);}
  .ProgramPurchases .status.COMPLETED {color: #5396f7;color: var(--bluefinity);}
  .ProgramPurchases .status.CANCELLED {color: #FF0000;color: var(--danger);}
  .ProgramPurchases .history table {margin: 0;}
  .ProgramPurchases .history tbody tr {border: 1px solid #BBBBBB;border: 1px solid var(--c3); border-right: 0;}
  .ProgramPurchases .history tr, .ProgramPurchases .history {background-color: #FFFFFF;background-color: var(--c1);}
  .ProgramPurchases .history th {padding: 0 10px 5px;}
  .ProgramPurchases .history td {padding: 2px 10px;}

/* SOCIAL SHARE */
  .SocialMediaShare {
    position: fixed;
    right: 0;
    top: 20vh;
    font-size: 30px;
    border: 1px solid #999999;
    border: 1px solid var(--c4);
    border-radius: 5px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #F0F0F0;
    background-color: var(--c2);
    padding: 10px;}
  .SocialMediaShare a {display: block;}
  .SocialMediaShare a:hover {-webkit-transform: scale(1.1);transform: scale(1.1); }

/* PROGRAM CALENDAR */
  .ProgramCalendar {}
  .ProgramCalendar .month {
    text-align: center;
    padding: 10px 0px;
    font-size: 16px;
    font-weight: 300;
    background-color: #F0F0F0;
    background-color: var(--c2);
    border-top: 1px solid #BBBBBB;
    border-top: 1px solid var(--c3);
    border-left: 1px solid #BBBBBB;
    border-left: 1px solid var(--c3);
    border-right: 1px solid #BBBBBB;
    border-right: 1px solid var(--c3);
  }
  .ProgramCalendar .month i {cursor: pointer; padding: 2px 10px;}
  .ProgramCalendar .days {border-top: 1px solid #BBBBBB;border-top: 1px solid var(--c3); border-left: 1px solid #BBBBBB; border-left: 1px solid var(--c3);}
  .ProgramCalendar .days .topRow {background-color: #F0F0F0;background-color: var(--c2); text-align: center; display: -webkit-inline-flex; display: inline-flex; -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; }
  .ProgramCalendar .days > div {
    display: inline-block;
    width: 14.28%;
    height: 50px;
    vertical-align: middle;
    border-bottom: 1px solid #BBBBBB;
    border-bottom: 1px solid var(--c3);
    border-right: 1px solid #BBBBBB;
    border-right: 1px solid var(--c3);
    color: #777777;
    color: var(--c5);
    font-weight: 300;
    padding: 1px 5px;
    position: relative;
  }
  .ProgramCalendar .days > div.today {background-color: #4CBF00;background-color: var(--brand); color: #111111; color: var(--c8); font-weight: 400;}

  .ProgramCalendar .executions {
    position: absolute;
    height: 50%;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #5396f7;
    background-color: var(--bluefinity);
    color: #FFFFFF;
    color: var(--c1);
    text-align: center;
    font-weight: 400;
    padding: 3px 0;
  }
  .ProgramCalendar .executions .programs {
    display: none;
    border: 2px solid #5396f7;
    border: 2px solid var(--bluefinity);
    background-color: #F0F0F0;
    background-color: var(--c2);
    position: absolute;
    white-space: nowrap;
    padding: 5px;
    color: #777777;
    color: var(--c5);
    text-align: left;
    z-index: 1;
    font-weight: 300;
  }
  .ProgramCalendar .executions:hover .programs {display: block;}
  .ProgramCalendar .executions .programs > div:hover {cursor: pointer; color: #333333; color: var(--c7); font-weight: 400;}
  .ProgramCalendar .executions .programs span {margin: 0 5px;}

/* AVAILABLE BALANCE */
  .AvailableBalance {}
    .AvailableBalance .barContainer {margin-bottom: 15px; overflow: hidden;}
    .AvailableBalance .bar {
      height: 22px;
      display: inline-block;
      width: 100%;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      color: #111111;
      color: var(--c8);
      font-weight: 400;
      transition: width 1s;
    }
    .AvailableBalance p {font-weight: 400; color: #111111; color: var(--c8);}
    .AvailableBalance .amount {margin-left: 5px; font-weight: 300; padding: 0;}
    .AvailableBalance .bar.balance {background-color: #1CB787;background-color: var(--bull);}
    .AvailableBalance .bar.balance .amount {color: #FFFFFF;color: var(--c1); }
    .AvailableBalance .bar.allocated {background-color: #FFCE00;background-color: var(--alert); width: 0%;}
    .AvailableBalance .allocated.amount {color: #FFCE00;color: var(--alert);}
    .AvailableBalance .bar.available {background-color: #5396f7;background-color: var(--bluefinity); width: 0%;}
    .AvailableBalance .available.amount {color: #5396f7;color: var(--bluefinity);}
    .AvailableBalance .bar.allocated-detail {background-color: #FF41A1;background-color: var(--bear); width: 0%;}
    .AvailableBalance .allocated-detail.amount {color: #FF41A1;color: var(--bear);}

/* EXCHANGE SETTINGS */
  .ExchangeSettings {padding: 5px 10px;}
    .ExchangeSettings .list {margin: 5px 0; background: #FFFFFF; background: var(--c1); color: #111111; color: var(--c8); padding: 5px 0; max-height: 300px; overflow: auto;}
      .ExchangeSettings .list > div {padding: 2px 15px;}
      .ExchangeSettings .list > div.item {color: #999999;color: var(--c4); cursor: pointer;}
      .ExchangeSettings .list > div.item:hover {background: #555555;background: var(--c6);}
        .ExchangeSettings .list > div.item .fa {float: right; padding: 2px;}
        .ExchangeSettings .list > div.item .fa:hover {color: #FFFFFF;color: var(--c1);}
    .ExchangeSettings .name {position: relative; margin-top: 8px;}
      .ExchangeSettings .name .fa {position: absolute; top: 0; padding: 8px; cursor: pointer; right: 0;}
      .ExchangeSettings .name .fa.green {right: 24px;}
    .ExchangeSettings .btn {margin-top: 8px;}

    .ExchangeSettings .themeCreator {}
      .ExchangeSettings .themeCreator .color {width: 20px; height: 20px; cursor: pointer; margin: 5px; display: inline-block; border: 1px solid black;}

/* DROPDOWN MENU */
  .uiMenu {position: relative; display: inline; font-family: 'Roboto', sans-serif; font-family: var(--font);}
    .uiMenu .dropdown, .uiMenu .subActions {position: absolute; background: #BBBBBB; background: var(--c3); z-index: 550; border: 2px solid #999999; border: 2px solid var(--c4); padding: 5px 0;}
    .uiMenu .dropdown {left: 20px;}
      .uiMenu .dropdown .action {white-space: nowrap; text-transform: none; color: #555555; color: var(--c6); font-weight: 400; padding: 0px 35px 0 10px; cursor: pointer;}
      .uiMenu .dropdown .action.split {border-bottom: 1px solid #555555;border-bottom: 1px solid var(--c6); margin: 8px;}
      .uiMenu .dropdown.inverted .action {padding: 0px 10px 0 35px; text-align: right;}
      .uiMenu .dropdown .action:hover {background: #FFFFFF;background: var(--c1);}
        .uiMenu .dropdown .action .fa {color: #111111;color: var(--c8); position: absolute; right: 10px; padding: 2px 0px;}
        .uiMenu .dropdown.inverted .action .fa {left: 10px; right: unset; /* transform: rotate(180deg); */}
      .uiMenu .dropdown .subActions {left: 100%;}
      .uiMenu .dropdown.inverted .subActions {right: 100%; left: unset;}
        .uiMenu .dropdown .subActions .action {padding: 0px 10px; color: #777777; color: var(--c5);}

/* SIDE MENU */
  .SideMenuOverlay {position: fixed; left: 0; right: 0; bottom: 0; top: 40px; z-index: 99; background: #0006;}
  .SideMenu {position: fixed; background: #FFFFFF; background: var(--c1); top: 40px; bottom: 0; width: 30vw; z-index: 100; right: 0; transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s; -webkit-transform: translateX(100%); transform: translateX(100%); padding: 10px 0; color: #111111; color: var(--c8); font-size: 16px;}
  .SideMenu.alt {left: 0; right: auto; -webkit-transform: translateX(-105%); transform: translateX(-105%); padding-left: calc((100vw - 1170px) / 2);}
  .SideMenu.active {-webkit-transform: none!important;transform: none!important;}
    .SideMenu .divider {border-bottom: 1px solid #999999;border-bottom: 1px solid var(--c4); padding: 0; margin: 5px 0;}
    .SideMenu > div {cursor: pointer; padding: 4px 15px; text-transform: uppercase; font-weight: 600; font-weight: 300; font-size: 14px;}
    .SideMenu > div:hover {background-color: #F0F0F0;background-color: var(--c2);}
    .SideMenu > div.active {background: #4CBF00;background: var(--brand);}
      .SideMenu > div i {float: right;}
  @media (max-width: 1200px) {
    .SideMenu.alt {padding-left: calc((100vw - 970px) / 2);}
  }
  @media (max-width: 992px) {
    .SideMenu.alt {padding-left: calc((100vw - 750px) / 2); width: 40vw;}
  }
  @media (max-width: 768px) {
    .SideMenu.alt {padding-left: 0; width: 50vw;}
  }

/* MY TOKENS */
  .myTokens .tokens, .myReferrals .tokens {border: 1px solid #777777;border: 1px solid var(--c5); font-weight: 600; margin: 0 !important; padding: 4px 10px;}
  .myTokens .tokens.fill, .myReferrals .tokens.fill {color: #FFFFFF;color: var(--c1); background: #777777; background: var(--c5);}
    .myTokens .tokens img, .myReferrals .tokens img {height: 18px; vertical-align: top; margin: 1px 10px 0 0;}

/* STEP PROGRESS BAR */
  .stepProgressBar {position: relative; width: 400px; margin: auto; height: 38px; margin-bottom: 25px; max-width: 100%;}
    .stepProgressBar .bar {position: absolute; top: 40%; height: 20%;}
    .stepProgressBar .bar.background {background: #F0F0F0;background: var(--c2); width: 100%;}
    .stepProgressBar .bar.fill {background: #777777;background: var(--c5); transition: width 1s;}
    .stepProgressBar .item {
      position: absolute;
      border-radius: 50%;
      background: #F0F0F0;
      background: var(--c2);
      height: 66%;
      top: 16%;
      width: 6%;
      text-align: center;
      color: #777777;
      color: var(--c5);
      font-family: 'Roboto', sans-serif;
      font-family: var(--font);
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
    }
    .stepProgressBar .item.active {background: #5396f7;background: var(--bluefinity); color: #FFFFFF; color: var(--c1); transition-delay: 0.65s;}
    .stepProgressBar .item.past {background: #777777;background: var(--c5); color: #FFFFFF; color: var(--c1);}
      .stepProgressBar .item .number {}
      .stepProgressBar .item .text {position: absolute; top: 110%; -webkit-transform: translateX(-50%); transform: translateX(-50%); left: 50%; color: #999999; color: var(--c4); font-weight: 600; white-space: nowrap;}
        .stepProgressBar .item.active .text {color: #5396f7;color: var(--bluefinity);}

/* TREND */
  .Trend {background-color: #FFFFFF;background-color: var(--c1);}
    .Trend .date {color: #BBBBBB;color: var(--c3);}
    .Trend .currency {font-size: 20px; vertical-align: middle; margin-right: 20px;}
      .Trend .currency img {margin-right: 5px; height: 25px; vertical-align: top;}
    .Trend .price {color: #777777;color: var(--c5); font-weight: 300; margin-top: 10px;}
    .Trend .select {float: right; min-width: 75px;}

/* HOLDINGS CHART */
  .HoldingsChart {background-color: #FFFFFF;background-color: var(--c1);}
  .HoldingsChart .select {float: right; min-width: 75px;}
  .HoldingsChart canvas {margin-top: 15px;}
  .HoldingsChart p > span {margin-left: 15px; color: #777777; color: var(--c5); font-weight: 400;}
  .HoldingsChart .currentHoldings {margin-bottom: 25px;}
  .HoldingsChart .currentHoldings .row > div {white-space: nowrap;}
  .HoldingsChart .currentHoldings img {height: 20px; vertical-align: top; margin: 0 5px;}
  .HoldingsChart .hovered-values {white-space: nowrap; font-size: 12px;}
  .HoldingsChart .hovered-values img {height: 15px; margin-right: 8px; vertical-align: top;}

/* NOTIFICATION CENTER */
  .NotificationCenterOverlay {position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 97; background-color: rgba(0,0,0,0.5);}
  .NotificationCenterModal .body {padding: 10px;}
  .NotificationCenter { position: fixed; top: 80px; bottom: 0; width: 25vw; padding: 15px; background: #F0F0F0; background: var(--c2); color: #333333; color: var(--c7); z-index: 98; transition: -webkit-transform 0.5s; transition: transform 0.5s; transition: transform 0.5s, -webkit-transform 0.5s; -webkit-transform: translateX(-100%); transform: translateX(-100%);}
  .mobile .NotificationCenter {right: 0; -webkit-transform: translateX(100%); transform: translateX(100%); width: 80vw; top: 30px;}
    .NotificationCenter.showing {-webkit-transform: none!important;transform: none!important;}
    .NotificationCenter .toggle {transition: -webkit-transform 0.5s;transition: transform 0.5s;transition: transform 0.5s, -webkit-transform 0.5s; -webkit-transform: translateX(100%); transform: translateX(100%); position: absolute; top: 0; right: 0; width: 40px; height: 40px; background: inherit; text-align: center; color: #111111; color: var(--c8); display: -webkit-flex; display: flex; -webkit-justify-content: center; justify-content: center; -webkit-flex-direction: column; flex-direction: column; cursor: pointer; z-index: 5;}
    .NotificationCenter.showing .toggle {-webkit-transform: none;transform: none;}
    .NotificationCenter .toggle.unread { color: #FFCE00; color: var(--alert); }
    .NotificationCenter .new {display: -webkit-flex;display: flex; -webkit-justify-content: center; justify-content: center; -webkit-flex-direction: column; flex-direction: column; position: absolute; left: 100%; overflow: hidden; background: inherit; white-space: nowrap; height: 100%; max-width: 0; transition: all 1s; direction: rtl;}
    .NotificationCenter .new.showing {max-width: 500px; padding: 0 20px;}
    .NotificationCenter h4 {color: #111111;color: var(--c8); margin: 0;}
    .NotificationCenter h5 {cursor: pointer; color: #777777; color: var(--c5); margin: 5px 0; display: inline-block;}
    .NotificationCenter .notifications {margin: 10px 0; overflow: auto; display: -webkit-flex; display: flex; -webkit-flex-flow: column; flex-flow: column; height: 100%;}
      .NotificationCenter .notifications > div {background: #BBBBBB;background: var(--c3); padding: 5px 25px 5px 10px; position: relative; cursor: pointer; margin: 10px 0;}
      .NotificationCenter .notifications > div:hover {background: #4CBF00;background: var(--brand);}
      .NotificationCenter .notifications > div.unread {background: #999999;background: var(--c4);}
      .NotificationCenter .notifications > div.unread:hover {background: #4CBF00;background: var(--brand);}
        .NotificationCenter .notifications > div .remove {position: absolute; right: 0; top: 0; padding: 5px 9px;}

/* TOOLTIP */
  .uiTooltipTrigger {display: inline-block;}
  .uiTooltip {background: #FFFFFF;background: var(--c1); border: 2px solid #777777; border: 2px solid var(--c5); position: fixed; font-size: 12px; padding: 2px 10px; font-family: 'Exo', sans-serif; font-family: var(--font2); z-index: 502; color: #111111; color: var(--c8);}
    .uiTooltip .arrow {position: absolute; width: 0; height: 0; top: -10px; left: 0; right: 0; margin: auto;}
    .uiTooltip.top .arrow {-webkit-transform: rotate(180deg);transform: rotate(180deg); top: auto; bottom: -10px;}
    .uiTooltip.left .arrow {-webkit-transform: rotate(90deg);transform: rotate(90deg); top: 0; bottom: 0; left: auto; right: -15px;}
    .uiTooltip.right .arrow {-webkit-transform: rotate(270deg);transform: rotate(270deg); top: 0; bottom: 0; left: -15px; right: auto;}
    .uiTooltip .arrow.first {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #777777;
      border-bottom: 10px solid var(--c5);
    }
    .uiTooltip .arrow.second {
      top: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #FFFFFF;
      border-bottom: 8px solid var(--c1);
    }
    .uiTooltip.top .arrow.second {top: auto; bottom: -8px;}
    .uiTooltip.left .arrow.second {right: -11px; top: 0;}
    .uiTooltip.right .arrow.second {left: -11px; top: 0;}

/* MODAL */
  .uiModal {position: fixed; top: 0; left: 0; background: #F0F0F0; background: var(--c2); border: 2px solid #999999; border: 2px solid var(--c4); width: 550px; margin: auto; z-index: 500; -webkit-animation-name: attention-glow; animation-name: attention-glow; -webkit-animation-duration: 1s; animation-duration: 1s; overflow-y: auto; overflow-x: hidden; color: #333333; color: var(--c7); max-width: 100vw;}
    .uiModal .head {background: #BBBBBB;background: var(--c3); padding: 5px 10px; cursor: move; min-height: 30px;}
      .uiModal .head .title {font-family: 'Exo', sans-serif;font-family: var(--font2); font-weight: 600; color: #111111; color: var(--c8); text-transform: uppercase;}
      .uiModal .head a {float: right; color: #111111; color: var(--c8); transition: -webkit-transform 0.2s; transition: transform 0.2s; transition: transform 0.2s, -webkit-transform 0.2s;}
      .uiModal .head a:hover {-webkit-transform: scale(1.4,1.4);transform: scale(1.4,1.4);}
    .uiModal .body {padding: 10px; max-height: calc(100vh - 150px); overflow-y: auto; overflow-x: hidden;}
      .uiModal .body .row {margin-bottom: 10px;}
        .uiModal .body .row p {margin: 5px 0;}
  .uiModal.mobile {width: 100vw; max-height: 100vh; top: 90px;}

/* CARROUSEL */
  .uiCarrousel {position: relative; overflow: hidden;}
    .uiCarrousel .slide {display: none; width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 4; transition: -webkit-transform 1s ease; transition: transform 1s ease; transition: transform 1s ease, -webkit-transform 1s ease;}
      .uiCarrousel .slide.left {display: block; -webkit-transform: translate(-100%,0); transform: translate(-100%,0);}
      .uiCarrousel.moved-left .slide.left {transition: none;}
      .uiCarrousel .slide.right {display: block; -webkit-transform: translate(100%,0); transform: translate(100%,0);}
      .uiCarrousel.moved-right .slide.right {transition: none;}
      .uiCarrousel .slide.showing {display: block; -webkit-transform: translate(0,0); transform: translate(0,0);}
    .uiCarrousel i {transition: -webkit-transform .2s linear;transition: transform .2s linear;transition: transform .2s linear, -webkit-transform .2s linear;}
    .uiCarrousel > i {position: absolute; font-size: 30px; margin: 0 20px; top: 50%; -webkit-transform: translate(0,-50%); transform: translate(0,-50%); cursor: pointer; z-index: 5;}
    .uiCarrousel > i:hover {-webkit-transform: scale(1.5) translate(0%, -30%);transform: scale(1.5) translate(0%, -30%);}
    .uiCarrousel .btnBack {left: 0;}
    .uiCarrousel .btnNext {right: 0;}
    .uiCarrousel .slidesList {position: absolute; bottom: 0; margin: 20px 0; text-align: center; width: 100%; z-index: 5;}
      .uiCarrousel .slidesList > div {display: inline-block; margin: 0 5px; font-size: 20px; cursor: pointer;}
        .uiCarrousel .slidesList > div i:hover {-webkit-transform: scale(1.4);transform: scale(1.4);}

/* GENERAL UI */
  .tag {border: 1px solid; padding: 5px 50px; display: inline-block; text-align: center; white-space: nowrap;}

  .well {padding: 10px 20px; border-radius: 0; background: #F0F0F0; background: var(--c2); box-shadow: none;}
  .well .row > div{margin: 5px 0;}

  .segment {position: relative; height: 100%; /* max-height: 100vh; */}
  .segment .spinner-container {
    background: rgba(255,255,255,0.75);
    background: var(--c0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:center;
            justify-content:center;
    z-index: 10;
  }
    .segment .spinner-container img {height: 60px; max-height: 100%;}

  .loginModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh!important;
    background: #00000080;
    pointer-events: auto;
    z-index: 50;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .MaintenanceBanner {-webkit-transform: translate(0,-80px);transform: translate(0,-80px); width: 40px; transition: width 1s ease .8s, -webkit-transform 1s ease; transition: transform 1s ease, width 1s ease .8s; transition: transform 1s ease, width 1s ease .8s, -webkit-transform 1s ease; white-space: nowrap; position: fixed; background-color: #FFCE00; background-color: var(--alert); top: 40px; z-index: 20; text-align: center; color: #111111; color: var(--c8); padding: 50px 0; font-size: 20px;}
  .MaintenanceBanner.open {-webkit-transform: translate(0,0);transform: translate(0,0); width: 100vw; transition: width 1s ease, -webkit-transform 1s ease .8s; transition: transform 1s ease .8s, width 1s ease; transition: transform 1s ease .8s, width 1s ease, -webkit-transform 1s ease .8s;}
    .MaintenanceBanner > i {margin-right: 15px;}
    .MaintenanceBanner .toggle {cursor: pointer; position: absolute; bottom: 10px; margin: auto; left: 0; right: 0;}

  .scrollBarContainer {position: absolute; right: 0; top: 0; bottom: 0; height: 100%; width: 10px;}
    .scrollBarContainer .scrollBar {width: 100%; height: 30%; background: #777777; background: var(--c5); position: absolute; top: 0; transition: height 0.5s;}
    .scrollBarContainer .scrollBar:hover {background: #999999;background: var(--c4);}

  .box {padding: 25px 40px 40px; margin-bottom: 20px; border: 1px solid #F0F0F0; border: 1px solid var(--c2);}
  .mobile .box {padding: 20px;}
    .box .row {margin: 10px -15px;}
    .box .row:first-child {margin-top: 0;}
    .box .row:last-child {margin-bottom: 0;}
      .box .row p {margin: 5px 0;}
    .box p {margin: 10px 0;}
    .box h3 {margin-bottom: 15px;}
    .box h4 {margin-bottom: 10px;}
    .box h5 {margin-bottom: 5px;}
    .box table {margin: 10px 0;}
    .box .btn-group {margin: 0 0 10px; white-space: nowrap;}
      .box .btn-group:last-child {margin: 0;}
    .boxTabs {margin: -25px -40px 25px; background: #999999; background: var(--c4); color: #FFFFFF; color: var(--c1);}
    .mobile .boxTabs {margin: -20px -20px 20px -20px;}
      .boxTabs > div {display: inline-block; cursor: pointer; padding: 10px 40px; font-weight: 600;}
      .boxTabs > div.active {background: #FFFFFF;background: var(--c1); color: #111111; color: var(--c8);}

  .alert {border: 2px solid #FF0000;border: 2px solid var(--danger); border-radius: 0; padding: 0 0 0 55px; position: relative; box-shadow: none; text-shadow: none; min-height: 26px;}
    .alert .fa {background: #FF0000;background: var(--danger); color: #FFFFFF; color: var(--c1); position: absolute; font-size: 18px; top: 0; bottom: 0; left: 0; display: -webkit-flex; display: flex; -webkit-justify-content: center; justify-content: center; -webkit-align-items: center; align-items: center; width: 50px;}
    .alert .text {color: #FF0000;color: var(--danger); font-weight: 600; font-size: 12px;}

  .tourTooltip {background: #FFFFFF;background: var(--c1); padding: 20px 30px; font-family: 'Roboto', sans-serif; font-family: var(--font); overflow: hidden;}
    .tourTooltip span {display: block;}
    .tourTooltip .title {font-size: 18px; font-weight: 600; margin-bottom: 10px;}
    .tourTooltip .content {font-size: 15px; margin-bottom: 30px;}

  .pagination {margin: 0; vertical-align: middle;}
    .pagination > li > a {border-color: #BBBBBB;border-color: var(--c3); color: #999999; color: var(--c4); font-weight: 600;}
    .pagination > li > span {margin: 0;}
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {border-radius: 0; font-weight: 600;}
    .pagination > li.active > span, .pagination > li.active > span:hover, .pagination > li > a:hover {background: #FFFFFF;background: var(--c1); border-color: #BBBBBB; border-color: var(--c3); color: #4CBF00; color: var(--brand);}

  .NewComponent {padding: 5px 10px;}

  .terms p {position: relative; margin: 15px 0 15px 50px;}
  .terms p > b:first-child {position: absolute; left: -50px;}
  .terms h5 {font-weight: 400; padding: 15px 10px; border: 1px solid #F0F0F0; border: 1px solid var(--c2); border-bottom: none; font-family: 'Roboto', sans-serif; font-family: var(--font);}

  .infoPage {background: #FFFFFF;background: var(--c1); color: #111111; color: var(--c8); padding: 10px 0 50px; min-height: calc(100vh - 40px);}
    .infoPage .container {position: relative;}
    .infoPage h1 {margin: 35px 0; font-weight: 100; text-align: center;}
    .infoPage h3 {font-family: 'Roboto', sans-serif;font-family: var(--font); margin: 25px 0 15px;}
    .infoPage ul {padding-left: 15px;}
    .infoPage table {}
      .infoPage table tr {background: none; border: 1px solid #999999; border: 1px solid var(--c4);}
        .infoPage table th {padding: 5px 20px; color: #111111; color: var(--c8); font-weight: 600; text-align: center; background-color: #F0F0F0; background-color: var(--c2);}
        .infoPage table td {border-right: 1px solid #999999;border-right: 1px solid var(--c4);}
        .infoPage table td:last-child {border: none;}
    .header-gradient {
      position: absolute;
      top: 0;
      width: 100vw;
      height: 200px;
      background: rgb(37,83,137);
      background: linear-gradient(to bottom, rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,#FFFFFF 100%);
      background: linear-gradient(to bottom, rgba(37,83,137,1) 0%,rgba(56,139,236,1) 50%,var(--c1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#255389', endColorstr='#ffffff',GradientType=0 );
    }
    .infoPage .bg, .header-bg {
      position: absolute; width: 100%; height: 100%; opacity: 0.1; top: 0; height: 200px;
    }

  .tokenImg {height: 18px;}

  .overlay {background-color: rgba(0,0,0,0.75)!important; position: fixed; left: 0; right: 0; bottom: 0; top: 0; z-index: 11;}

  .redirecting {
    position: fixed;
    padding: 0!important;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 480px;
    max-width: 90%;
    bottom: 0;
    height: 324px;
    z-index: 12;
  }
    .redirecting .head {
      background-image: url('/img/logo-icon-white.png');
      height: 50px;
      background-color: #111111;
      background-color: var(--c8);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto 80%;
    }
    .redirecting .body {padding: 30px 50px; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; -webkit-flex-direction: column; flex-direction: column; -webkit-justify-content: center; justify-content: center; background-color: #FFFFFF; background-color: var(--c1);}
    .redirecting p {margin: 10px 0 25px;}
    .redirecting .btn {margin: 2px 0;}

  #popout-content-container {height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; }
